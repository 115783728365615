// @group General
// ============================================================================
form {
	margin-bottom: @baseline / @em;

	&.stacked {
		.field {
			label {
				display: block;
				width: auto;
			}

			input,
			select,
			textarea {
				width: 220 / @em;
			}
		}
	}

	.field {
		@label-width: 20%;

		margin: ((@baseline / 2) / @em) 0;
		position: relative;

		&.wide {
			@label-width: 100%;

			label {
				width: @label-width;
			}

			ul {
				margin-left: 139 / @em !important;
			}
		}

		&.width-l {
			input,
			select,
			textarea {
				width: 180 / @em;
			}
		}

		&.width-m {
			input,
			select,
			textarea {
				width: 120 / @em;
			}
		}

		&.width-s {
			input,
			select,
			textarea {
				width: 80 / @em;
			}
		}

		&.width-xs {
			input,
			select,
			textarea {
				width: 50 / @em;
			}
		}

		label {
			display: inline-block;
			font-weight: bold;
			width: @label-width;
			vertical-align: middle;
		}

		input,
		select,
		textarea {
			.box-sizing(border-box);
			display: inline-block;
			height: (@baseline + 8) / @em;
			padding: (4 / @em);
			width: 180 / @em;
			border: 1px solid @input-border-color;
			vertical-align: middle;
			font: inherit;
		}

		input[type=checkbox],
		input[type=radio] {
			width: auto;
			height: auto;
			padding: 0;
			border: none;
		}

		textarea {
			height: 80 / @em;
		}

		&.radio-select {
			label {
				vertical-align: top;
			}

			ul {
				list-style: none;
				display: inline-block;
				width: 70%;
				margin-left: 0;

				li {
					label {
						display: inline;
						font-weight: normal;

						.description {
							display: block;
							font-style: italic;
							padding-left: 16 / @em;
						}
					}
				}
			}
		}

		&.checkbox-input {
			.help {
				display: block;
				font-weight: bold;
			}

			label {
				display: inline;
				font-weight: normal;
			}

			&.errors .field-error-message {
				padding: 0;
				margin: 0;
			}
		}

		&.errors {
			input,
			select,
			textarea {
				border-color: @alert-background-error;
			}

			.field-error-message {
				margin-left: @label-width;
				padding-left: 3px;

				@media only screen and (max-width:420px), only screen and (max-device-width:700px) {
					margin: 0;
					padding: 0;
				}
			}
		}

		.shipping-extra {
			background: @light-background;
			width: 75%;
			padding: 10 / @em;
			display: none;
			margin: (4 / @em) 0 (8 / @em) (16 / @em);

			&.errors {
				select {
					border-color: @alert-background-error;
				}
			}

			.itella-search-field,
			.itella-search-button,
			.itella-select {
				display: inline-block;
				vertical-align: middle;
			}

			.itella-search-field {
				width: 15%;
			}

			.itella-search-button {
				.button-green();
				width: 20%;
				height: (23 / @em);
				margin: 0 (4 / @em);
				line-height: 28px;
				padding: 0;
			}

			.itella-select {
				width: 60%;
			}

			.help {
				display: block;
				margin-top: (4 / @em);
			}
		}

		.active {
			.shipping-extra {
				display: block;
			}
		}

		&.show-optional .required-marker {
			display: none;
		}
	}

	input,
	select,
	textarea {
		color: @text-color;
	}

	input[type=submit] {
		.button-green();
		margin: ((@baseline / 2) / @em) 0;
	}

}

.actions {
	.clearfix;
	border-top: 1px solid @brand-green;
	padding: (@baseline / @em) 0;
	margin-top: @baseline / @em;

	input,
	input[type=submit],
	.button {
		.pull(right);
		margin-top: 0;
		margin-bottom: 0;
		margin-right: 0;

		&.left {
			margin-left: 0;
		}
	}
}

// Placeholder styling, from http://stackoverflow.com/a/2610741

// WebKit browsers
::-webkit-input-placeholder {
	color: @placeholder-color;
	opacity: 1;
}

:focus::-webkit-input-placeholder {
	color: @placeholder-color-focus;
	opacity: 1;
}

// // Mozilla Firefox 4 to 18
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
	color: @placeholder-color;
	opacity: 1;
}

:focus:-moz-placeholder {
	color: @placeholder-color-focus;
	opacity: 1;
}

// Mozilla Firefox 19+
::-moz-placeholder {
	color: @placeholder-color;
	opacity: 1;
}

:focus::-moz-placeholder {
	color: @placeholder-color-focus;
	opacity: 1;
}

// Internet Explorer 10+
:-ms-input-placeholder {
	color: @placeholder-color;
	opacity: 1;
}

:focus:-ms-input-placeholder {
	color: @placeholder-color;
	opacity: 1;
}
// @end General
// ----------------------------------------------------------------------------
