.border {
    background-color: @border-color;
    display: block;
    height: 4 / @em;
    width: 100%;

    &.green {
        background-color: @brand-green;
    }

    &.vertical {
        height: 100%;
        width: 1px;
    }
}


// @group Badges
// ============================================================================
.badge {
    .box-sizing(border-box);
    background-color: @badge-background;
    color: @badge-color;
    display: inline-block;
    font-family: @brand-font;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    padding: (2 / @em) (5 / @em) (0 / @em) (5 / @em);

    &.badge-title {
        margin: 0 (5 / @em) (5 / @em) 0;
    }

    &.badge-new, &.badge-sale {
        background-color: @brand-green;
    }

    &.badge-out-of-stock {
        margin-left: 5 / @em;
    }

    table & {
        display: block;
    }

    a:hover &.medicine {
        background-color: @badge-background-hover;
        color: @badge-color-hover;
    }
}
// @end Badges
// ----------------------------------------------------------------------------


// @group Buttons
// ============================================================================
.button (@color:@button-color-default, @color-hover:@button-color-default-hover, @background:@button-background-default, @background-hover:@button-background-default-hover) {
    background-color: @background;
    border: none;
    color: @color;
    display: inline-block;
    font-family: @brand-font;
    font-size: 14 / @em;
    font-weight: bold;
    margin: (@baseline / @em) 0;
    padding: (8 / @em) (10 / @em);
    text-align: center;
    text-transform: uppercase;

    &:hover,
    &:active {
        color: @color-hover;
        background-color: @background-hover;
        text-decoration: none;
    }

    &.disabled {
        background-color: @button-background-disabled;
        color: @button-color-disabled;
        cursor: default;

        &:hover {
            background-color: @button-background-disabled-hover;
            color: @button-color-disabled-hover;
        }
    }
}

.button-green () {
    .button(@button-color-primary, @button-color-primary-hover, @button-background-primary, @button-background-primary-hover);
}

.button-light-grey () {
    .button(@button-color-default, @button-color-default-hover, @button-background-default, @button-background-default-hover);
}

.button-dark-grey () {
    .button(@button-color-dark, @button-color-dark-hover, @button-background-dark, @button-background-dark-hover);
}

button,
.button {
    .button();
}
// @end Buttons
// ----------------------------------------------------------------------------

.flex-control-paging {
    li {
        a {
            width: 8px;
            height: 8px;
        }
    }
}

.flex-direction-nav a {
    overflow: visible;
    margin: -10px 10px 0 10px;
}

.stock-count {
    color: #999;

    &:before {
        border-radius: 50%;
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 10px;
    }

    &.in-stock:before {
        background: green;
    }

    &.out-of-stock:before {
        background: red;
    }
}

.parenthesized:before {
    content: "(";
}

.parenthesized:after {
    content: ")";
}
