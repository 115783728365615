// @group Alerts
// ============================================================================
.alert {
	.box-sizing(border-box);
	background-color: @alert-background;
	color: @alert-color;
	padding: @baseline / @em;
	text-shadow: 1px 1px 1px rgba(0,0,0,.3);
	margin-bottom: @baseline / @em;

	h1,
	h2,
	h3,
	h4 {
		color: inherit;
	}

	a {
		color: inherit;
		text-decoration: underline;
	}

	*:last-child {
		margin-bottom: 0;
	}

	&.alert-error {
		background-color: @alert-background-error;
		color: @alert-color-error;
	}

	&.alert-warning {
		background-color: @alert-background-warning;
		color: @alert-color-warning;
	}

	&.alert-info {
		background-color: @alert-background-info;
		color: @alert-color-info;
	}

	&.alert-success {
		background-color: @alert-background-success;
		color: @alert-color-success;
	}
}
// @end Alerts
// ----------------------------------------------------------------------------
