// @group CSS3 properties
// ============================================================================
.box-sizing (@arguments) {
	-moz-box-sizing: @arguments;
	-webkit-box-sizing: @arguments;
	box-sizing: @arguments;
}

.border-radius (@arguments) {
	-moz-border-radius: @arguments;
	-webkit-border-radius: @arguments;
	border-radius: @arguments;
}

.box-shadow (@arguments) {
	-moz-box-shadow: @arguments;
	-webkit-box-shadow: @arguments;
	box-shadow: @arguments;
}

.gradient(@color: #333, @start: #000, @stop: #FFF) {
	background: @color;
	background: -webkit-gradient(linear, left bottom, left top, color-stop(0, @start), color-stop(1, @stop));
	background: -ms-linear-gradient(bottom, @start, @stop);
	background: -moz-linear-gradient(center bottom, @start 0%, @stop 100%);
	background: -o-linear-gradient(@stop, @start);
	filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",@stop,@start));
}
// @end CSS3 properties
// ----------------------------------------------------------------------------