// @group Fonts
// ============================================================================
@font-face {
	font-family: 'DIN Pro';
	src: url('../../fonts/DINWebPro.eot');
	src: url('../../fonts/DINWebPro.eot?#iefix') format('embedded-opentype'),
		 url('../../fonts/DINWebPro.woff') format('woff'),
		 url('../../fonts/DINCompPro.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'DIN Pro';
	src: url('../../fonts/DINWebPro-Bold.eot');
	src: url('../../fonts/DINWebPro-Bold.eot?#iefix') format('embedded-opentype'),
		 url('../../fonts/DINWebPro-Bold.woff') format('woff'),
		 url('../../fonts/DINCompPro-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'DIN Pro';
	src: url('../../fonts/DINWebPro-Ita.eot');
	src: url('../../fonts/DINWebPro-Ita.eot?#iefix') format('embedded-opentype'),
		 url('../../fonts/DINWebPro-Ita.woff') format('woff'),
		 url('../../fonts/DINCompPro-Ita.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'DIN Pro';
	src: url('../../fonts/DINWebPro-BoldIta.eot');
	src: url('../../fonts/DINWebPro-BoldIta.eot?#iefix') format('embedded-opentype'),
		 url('../../fonts/DINWebPro-BoldIta.woff') format('woff'),
		 url('../../fonts/DINCompPro-BoldIta.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}
// @end Fonts
// ----------------------------------------------------------------------------

// @group Base
// ============================================================================
body {
	font-size: @font-size + 0px;
	font-family: @default-font;
	color: @text-color;
	line-height: @line-height;
}
// @end Base
// ----------------------------------------------------------------------------


// @group Headings
// ============================================================================
h1,
h2,
h3,
h4,
h5,
h6 {
	color: @brand-green;
	font-family: @brand-font;
	font-weight: bold;
	margin-bottom: (@gutter / 4) / @em;
	margin-top: (@gutter / 2) / @em;
	text-transform: uppercase;
	line-height: 1em;

	&:first-child {
		margin-top: 0;
	}

	&.force-margin {
		margin-bottom: (@gutter / 4) / @em !important;
		margin-top: (@gutter / 2) / @em !important;
	}

	&.highlight {
		// .border-radius(@border-radius / @em);
		background: transparent url(../img/highlight-background.png) no-repeat scroll center top;
		color: white;
		margin-bottom: 10 / @em;
		padding: (5 / @em) 0 (12 / @em) 0;
		position: relative;
		text-align: center;

		// &:after {
		// 	background: transparent;
		// 	border: 125px solid transparent;
		// 	border-top: 9px solid #32a039;
		// 	bottom: -134px;
		// 	content: " ";
		// 	display: block;
		// 	height: 0;
		// 	left: 0;
		// 	overflow: hidden;
		// 	position: absolute;
		// 	width: 0;
		// 	z-index: 0;
		// }
	}

	.breadcrumb + & {
		margin-top: 0;
	}
}

h1 {
	font-size: 20 / @em;
	line-height: 14 / @em;
}

h2 {
	font-size: 20 / @em;
	line-height: 14 / @em;
}

.faux-title (@size:16){
	color: @brand-green;
	font-size: @size / @em;
	font-family: @brand-font;
	font-weight: bold;
	text-transform: uppercase;
}

.brand-font {
	font-family: @brand-font;
}

.green {
	color: @brand-green;
}
// @end Headings
// ----------------------------------------------------------------------------


// @group Text
// ============================================================================
p,
ol,
ul,
blockquote,
address,
table {
	margin: 0 0 (@baseline / @em) 0;
}

hr {
	height: 1px;
	background: @border-color;
	border: none;
	color: transparent;
	margin: (@baseline / @em) 0;
}

ol,
ul {
	list-style-position: outside;
	margin-left: 18 / @em;

	li {
		margin: ((@baseline / 4) / @em) 0;
	}
}

address {
	font-style: normal;
}

blockquote {

}

a {
	color: @brand-green;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}

	&.more {
		&:after {
			content: " »";
		}
	}
}

.small {
	font-size: 80%;
}

.smallest{
	font-size: 70%;
}
// @end Text
// ----------------------------------------------------------------------------


// @group Tables
// ============================================================================
table {
	margin-bottom: (1 * @baseline) / @em;

	&.incognito {
		th,
		td {
			border: none;
			padding: (4 / @em) ((@gutter / 4) / @em);
			vertical-align: top;

			&:first-child {
				padding-left: 0;
			}
		}
	}

	&.compact {
		th,
		td {
			padding: (4 / @em) ((@gutter / 4) / @em);
		}
	}

	&.striped {
		tr:nth-child(odd) {
			th,
			td {
				background-color: @light-background;
			}
		}
	}

	&.no-border {
		th,
		td {
			border: none;
		}
	}

	&.border-top {
		border-top: 1px solid @border-color;
	}

	&.tax-report-table {
		width: 100%;
	}

	th,
	td {
		border-bottom: 1px solid @border-color;
		padding: (@baseline / @em) ((@gutter / 4) / @em);
	}

	thead {
		th {
			.faux-title();
			text-align: left;
			color: @text-color;
			padding-top: (@baseline / 2) / @em;
			padding-bottom: (@baseline / 2) / @em;
		}
	}

}
// @end Tables
// ----------------------------------------------------------------------------