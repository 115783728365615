.right {
  float: right;
}
@media only screen and (max-width: 420px), only screen and (max-device-width: 700px) {
  .right.no-mobile-float {
    float: none;
  }
}
.left {
  float: left !important;
}
@media only screen and (max-width: 420px), only screen and (max-device-width: 700px) {
  .left.no-mobile-float {
    float: none;
  }
}
.clear {
  clear: both;
}
.clearfix {
  zoom: 1;
}
.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}
.clearfix:after {
  clear: both;
}
.centered {
  margin: 0 auto;
}
.block {
  display: block;
}
.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left !important;
}
.text-error {
  color: #c41c1c;
}
/*
	Frameless			<http://framelessgrid.com/>
	by Joni Korpi 		<http://jonikorpi.com/>
	licensed under CC0	<http://creativecommons.org/publicdomain/zero/1.0/>
*/
.container {
  zoom: 1;
  width: 80em;
  margin: 0 auto;
}
.container:before,
.container:after {
  content: "";
  display: table;
}
.container:after {
  clear: both;
}
/*
	Margin, padding, and border resets
	except for form elements
*/
body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
th,
td,
article,
aside,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
  border: 0;
}
/*
	Consistency fixes
	adopted from http://necolas.github.com/normalize.css/
*/
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
audio,
canvas,
video {
  display: block;
}
html {
  height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  min-height: 100%;
  font-size: 100%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}
b,
strong {
  font-weight: bold;
}
abbr[title] {
  border-bottom: 1px dotted;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a img,
img {
  -ms-interpolation-mode: bicubic;
  border: 0;
}
input,
textarea,
button,
select {
  margin: 0;
  font-size: 100%;
  line-height: normal;
  vertical-align: baseline;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}
input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
textarea {
  overflow: auto;
}
/*
	These are easy to forget
*/
::selection {
  background: #32a039;
  color: white;
}
::-moz-selection {
  background: #32a039;
  color: white;
}
img::selection {
  background: transparent;
}
img::-moz-selection {
  background: transparent;
}
body {
  -webkit-tap-highlight-color: rgba(50, 160, 57, 0.62);
}
@font-face {
  font-family: 'DIN Pro';
  src: url('../../fonts/DINWebPro.eot');
  src: url('../../fonts/DINWebPro.eot?#iefix') format('embedded-opentype'), url('../../fonts/DINWebPro.woff') format('woff'), url('../../fonts/DINCompPro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'DIN Pro';
  src: url('../../fonts/DINWebPro-Bold.eot');
  src: url('../../fonts/DINWebPro-Bold.eot?#iefix') format('embedded-opentype'), url('../../fonts/DINWebPro-Bold.woff') format('woff'), url('../../fonts/DINCompPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'DIN Pro';
  src: url('../../fonts/DINWebPro-Ita.eot');
  src: url('../../fonts/DINWebPro-Ita.eot?#iefix') format('embedded-opentype'), url('../../fonts/DINWebPro-Ita.woff') format('woff'), url('../../fonts/DINCompPro-Ita.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'DIN Pro';
  src: url('../../fonts/DINWebPro-BoldIta.eot');
  src: url('../../fonts/DINWebPro-BoldIta.eot?#iefix') format('embedded-opentype'), url('../../fonts/DINWebPro-BoldIta.woff') format('woff'), url('../../fonts/DINCompPro-BoldIta.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
body {
  font-size: 12px;
  font-family: arial, helvetica, sans-serif;
  color: #424242;
  line-height: 1.66666667em;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #32a039;
  font-family: "DIN Pro", arial, helvetica, sans-serif;
  font-weight: bold;
  margin-bottom: 0.625em;
  margin-top: 1.25em;
  text-transform: uppercase;
  line-height: 1em;
}
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0;
}
h1.force-margin,
h2.force-margin,
h3.force-margin,
h4.force-margin,
h5.force-margin,
h6.force-margin {
  margin-bottom: 0.625em !important;
  margin-top: 1.25em !important;
}
h1.highlight,
h2.highlight,
h3.highlight,
h4.highlight,
h5.highlight,
h6.highlight {
  background: transparent url(../img/highlight-background.png) no-repeat scroll center top;
  color: white;
  margin-bottom: 0.83333333em;
  padding: 0.41666667em 0 1em 0;
  position: relative;
  text-align: center;
}
.breadcrumb + h1,
.breadcrumb + h2,
.breadcrumb + h3,
.breadcrumb + h4,
.breadcrumb + h5,
.breadcrumb + h6 {
  margin-top: 0;
}
h1 {
  font-size: 1.66666667em;
  line-height: 1.16666667em;
}
h2 {
  font-size: 1.66666667em;
  line-height: 1.16666667em;
}
.brand-font {
  font-family: "DIN Pro", arial, helvetica, sans-serif;
}
.green {
  color: #32a039;
}
p,
ol,
ul,
blockquote,
address,
table {
  margin: 0 0 1.66666667em 0;
}
hr {
  height: 1px;
  background: #dadada;
  border: none;
  color: transparent;
  margin: 1.66666667em 0;
}
ol,
ul {
  list-style-position: outside;
  margin-left: 1.5em;
}
ol li,
ul li {
  margin: 0.41666667em 0;
}
address {
  font-style: normal;
}
a {
  color: #32a039;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
a.more:after {
  content: " »";
}
.small {
  font-size: 80%;
}
.smallest {
  font-size: 70%;
}
table {
  margin-bottom: 1.66666667em;
}
table.incognito th,
table.incognito td {
  border: none;
  padding: 0.33333333em 0.625em;
  vertical-align: top;
}
table.incognito th:first-child,
table.incognito td:first-child {
  padding-left: 0;
}
table.compact th,
table.compact td {
  padding: 0.33333333em 0.625em;
}
table.striped tr:nth-child(odd) th,
table.striped tr:nth-child(odd) td {
  background-color: #ededed;
}
table.no-border th,
table.no-border td {
  border: none;
}
table.border-top {
  border-top: 1px solid #dadada;
}
table.tax-report-table {
  width: 100%;
}
table th,
table td {
  border-bottom: 1px solid #dadada;
  padding: 1.66666667em 0.625em;
}
table thead th {
  color: #32a039;
  font-size: 1.33333333em;
  font-family: "DIN Pro", arial, helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
  color: #424242;
  padding-top: 0.83333333em;
  padding-bottom: 0.83333333em;
}
.border {
  background-color: #dadada;
  display: block;
  height: 0.33333333em;
  width: 100%;
}
.border.green {
  background-color: #32a039;
}
.border.vertical {
  height: 100%;
  width: 1px;
}
.badge {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #b6b6b6;
  color: white;
  display: inline-block;
  font-family: "DIN Pro", arial, helvetica, sans-serif;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  padding: 0.16666667em 0.41666667em 0em 0.41666667em;
}
.badge.badge-title {
  margin: 0 0.41666667em 0.41666667em 0;
}
.badge.badge-new,
.badge.badge-sale {
  background-color: #32a039;
}
.badge.badge-out-of-stock {
  margin-left: 0.41666667em;
}
table .badge {
  display: block;
}
a:hover .badge.medicine {
  background-color: #585858;
  color: white;
}
button,
.button {
  background-color: #d6d6d6;
  border: none;
  color: #424242;
  display: inline-block;
  font-family: "DIN Pro", arial, helvetica, sans-serif;
  font-size: 1.16666667em;
  font-weight: bold;
  margin: 1.66666667em 0;
  padding: 0.66666667em 0.83333333em;
  text-align: center;
  text-transform: uppercase;
}
button:hover,
.button:hover,
button:active,
.button:active {
  color: #424242;
  background-color: #b5b5b5;
  text-decoration: none;
}
button.disabled,
.button.disabled {
  background-color: #dedede;
  color: #585858;
  cursor: default;
}
button.disabled:hover,
.button.disabled:hover {
  background-color: #dedede;
  color: #585858;
}
.flex-control-paging li a {
  width: 8px;
  height: 8px;
}
.flex-direction-nav a {
  overflow: visible;
  margin: -10px 10px 0 10px;
}
.stock-count {
  color: #999;
}
.stock-count:before {
  border-radius: 50%;
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}
.stock-count.in-stock:before {
  background: green;
}
.stock-count.out-of-stock:before {
  background: red;
}
.parenthesized:before {
  content: "(";
}
.parenthesized:after {
  content: ")";
}
form {
  margin-bottom: 1.66666667em;
}
form.stacked .field label {
  display: block;
  width: auto;
}
form.stacked .field input,
form.stacked .field select,
form.stacked .field textarea {
  width: 18.33333333em;
}
form .field {
  margin: 0.83333333em 0;
  position: relative;
}
form .field.wide label {
  width: 100%;
}
form .field.wide ul {
  margin-left: 11.58333333em !important;
}
form .field.width-l input,
form .field.width-l select,
form .field.width-l textarea {
  width: 15em;
}
form .field.width-m input,
form .field.width-m select,
form .field.width-m textarea {
  width: 10em;
}
form .field.width-s input,
form .field.width-s select,
form .field.width-s textarea {
  width: 6.66666667em;
}
form .field.width-xs input,
form .field.width-xs select,
form .field.width-xs textarea {
  width: 4.16666667em;
}
form .field label {
  display: inline-block;
  font-weight: bold;
  width: 20%;
  vertical-align: middle;
}
form .field input,
form .field select,
form .field textarea {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  height: 2.33333333em;
  padding: 0.33333333em;
  width: 15em;
  border: 1px solid #b6b6b6;
  vertical-align: middle;
  font: inherit;
}
form .field input[type=checkbox],
form .field input[type=radio] {
  width: auto;
  height: auto;
  padding: 0;
  border: none;
}
form .field textarea {
  height: 6.66666667em;
}
form .field.radio-select label {
  vertical-align: top;
}
form .field.radio-select ul {
  list-style: none;
  display: inline-block;
  width: 70%;
  margin-left: 0;
}
form .field.radio-select ul li label {
  display: inline;
  font-weight: normal;
}
form .field.radio-select ul li label .description {
  display: block;
  font-style: italic;
  padding-left: 1.33333333em;
}
form .field.checkbox-input .help {
  display: block;
  font-weight: bold;
}
form .field.checkbox-input label {
  display: inline;
  font-weight: normal;
}
form .field.checkbox-input.errors .field-error-message {
  padding: 0;
  margin: 0;
}
form .field.errors input,
form .field.errors select,
form .field.errors textarea {
  border-color: #c41c1c;
}
form .field.errors .field-error-message {
  margin-left: 20%;
  padding-left: 3px;
}
@media only screen and (max-width: 420px), only screen and (max-device-width: 700px) {
  form .field.errors .field-error-message {
    margin: 0;
    padding: 0;
  }
}
form .field .shipping-extra {
  background: #ededed;
  width: 75%;
  padding: 0.83333333em;
  display: none;
  margin: 0.33333333em 0 0.66666667em 1.33333333em;
}
form .field .shipping-extra.errors select {
  border-color: #c41c1c;
}
form .field .shipping-extra .itella-search-field,
form .field .shipping-extra .itella-search-button,
form .field .shipping-extra .itella-select {
  display: inline-block;
  vertical-align: middle;
}
form .field .shipping-extra .itella-search-field {
  width: 15%;
}
form .field .shipping-extra .itella-search-button {
  background-color: #32a039;
  border: none;
  color: #ffffff;
  display: inline-block;
  font-family: "DIN Pro", arial, helvetica, sans-serif;
  font-size: 1.16666667em;
  font-weight: bold;
  margin: 1.66666667em 0;
  padding: 0.66666667em 0.83333333em;
  text-align: center;
  text-transform: uppercase;
  width: 20%;
  height: 1.91666667em;
  margin: 0 0.33333333em;
  line-height: 28px;
  padding: 0;
}
form .field .shipping-extra .itella-search-button:hover,
form .field .shipping-extra .itella-search-button:active {
  color: #ffffff;
  background-color: #18701d;
  text-decoration: none;
}
form .field .shipping-extra .itella-search-button.disabled {
  background-color: #dedede;
  color: #585858;
  cursor: default;
}
form .field .shipping-extra .itella-search-button.disabled:hover {
  background-color: #dedede;
  color: #585858;
}
form .field .shipping-extra .itella-select {
  width: 60%;
}
form .field .shipping-extra .help {
  display: block;
  margin-top: 0.33333333em;
}
form .field .active .shipping-extra {
  display: block;
}
form .field.show-optional .required-marker {
  display: none;
}
form input,
form select,
form textarea {
  color: #424242;
}
form input[type=submit] {
  background-color: #32a039;
  border: none;
  color: #ffffff;
  display: inline-block;
  font-family: "DIN Pro", arial, helvetica, sans-serif;
  font-size: 1.16666667em;
  font-weight: bold;
  margin: 1.66666667em 0;
  padding: 0.66666667em 0.83333333em;
  text-align: center;
  text-transform: uppercase;
  margin: 0.83333333em 0;
}
form input[type=submit]:hover,
form input[type=submit]:active {
  color: #ffffff;
  background-color: #18701d;
  text-decoration: none;
}
form input[type=submit].disabled {
  background-color: #dedede;
  color: #585858;
  cursor: default;
}
form input[type=submit].disabled:hover {
  background-color: #dedede;
  color: #585858;
}
.actions {
  zoom: 1;
  border-top: 1px solid #32a039;
  padding: 1.66666667em 0;
  margin-top: 1.66666667em;
}
.actions:before,
.actions:after {
  content: "";
  display: table;
}
.actions:after {
  clear: both;
}
.actions input,
.actions input[type=submit],
.actions .button {
  float: right;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0;
}
.actions input.left,
.actions input[type=submit].left,
.actions .button.left {
  margin-left: 0;
}
::-webkit-input-placeholder {
  color: #b5b5b5;
  opacity: 1;
}
:focus::-webkit-input-placeholder {
  color: transparent;
  opacity: 1;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #b5b5b5;
  opacity: 1;
}
:focus:-moz-placeholder {
  color: transparent;
  opacity: 1;
}
::-moz-placeholder {
  color: #b5b5b5;
  opacity: 1;
}
:focus::-moz-placeholder {
  color: transparent;
  opacity: 1;
}
:-ms-input-placeholder {
  color: #b5b5b5;
  opacity: 1;
}
:focus:-ms-input-placeholder {
  color: #b5b5b5;
  opacity: 1;
}
.alert {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #929292;
  color: #ffffff;
  padding: 1.66666667em;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  margin-bottom: 1.66666667em;
}
.alert h1,
.alert h2,
.alert h3,
.alert h4 {
  color: inherit;
}
.alert a {
  color: inherit;
  text-decoration: underline;
}
.alert *:last-child {
  margin-bottom: 0;
}
.alert.alert-error {
  background-color: #c41c1c;
  color: #ffffff;
}
.alert.alert-warning {
  background-color: #eaab1c;
  color: #ffffff;
}
.alert.alert-info {
  background-color: #1c7fc4;
  color: #ffffff;
}
.alert.alert-success {
  background-color: #73c41c;
  color: #ffffff;
}
.alertify-show,
.alertify-log {
  -webkit-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1);
  /* older webkit */
  -webkit-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  /* easeOutBack */
}
.alertify-hide {
  -webkit-transition: all 250ms cubic-bezier(0.6, 0, 0.735, 0.045);
  /* older webkit */
  -webkit-transition: all 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -moz-transition: all 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -ms-transition: all 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -o-transition: all 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  transition: all 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  /* easeInBack */
}
.alertify-cover {
  -moz-box-shadow: 0 0 500px 0 rgba(0, 0, 0, 0.8) inset;
  -webkit-box-shadow: 0 0 500px 0 rgba(0, 0, 0, 0.8) inset;
  box-shadow: 0 0 500px 0 rgba(0, 0, 0, 0.8) inset;
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99999;
}
.alertify-hidden {
  top: -50px;
  visibility: hidden;
}
.alertify {
  position: fixed;
  z-index: 99999;
  top: 50px;
  left: 50%;
  width: 550px;
  margin-left: -275px;
  background-color: white;
  text-align: center;
}
.alertify .alertify-message {
  color: #32a039;
  font-size: 1.33333333em;
  font-family: "DIN Pro", arial, helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 1.66666667em;
}
.alertify .alertify-buttons .alertify-button {
  background-color: #d6d6d6;
  border: none;
  color: #424242;
  display: inline-block;
  font-family: "DIN Pro", arial, helvetica, sans-serif;
  font-size: 1.16666667em;
  font-weight: bold;
  margin: 1.66666667em 0;
  padding: 0.66666667em 0.83333333em;
  text-align: center;
  text-transform: uppercase;
  margin: 0 1.25em;
}
.alertify .alertify-buttons .alertify-button:hover,
.alertify .alertify-buttons .alertify-button:active {
  color: #424242;
  background-color: #b5b5b5;
  text-decoration: none;
}
.alertify .alertify-buttons .alertify-button.disabled {
  background-color: #dedede;
  color: #585858;
  cursor: default;
}
.alertify .alertify-buttons .alertify-button.disabled:hover {
  background-color: #dedede;
  color: #585858;
}
.alertify .alertify-buttons .alertify-button:hover,
.alertify .alertify-buttons .alertify-button:active {
  color: #424242;
  background-color: #b5b5b5;
  text-decoration: none;
}
.alertify .alertify-buttons .alertify-button.disabled {
  background-color: #dedede;
  color: #585858;
  cursor: default;
}
.alertify .alertify-buttons .alertify-button.disabled:hover {
  background-color: #dedede;
  color: #585858;
}
.alertify .alertify-buttons .alertify-button-ok {
  background-color: #32a039;
  border: none;
  color: #ffffff;
  display: inline-block;
  font-family: "DIN Pro", arial, helvetica, sans-serif;
  font-size: 1.16666667em;
  font-weight: bold;
  margin: 1.66666667em 0;
  padding: 0.66666667em 0.83333333em;
  text-align: center;
  text-transform: uppercase;
}
.alertify .alertify-buttons .alertify-button-ok:hover,
.alertify .alertify-buttons .alertify-button-ok:active {
  color: #ffffff;
  background-color: #18701d;
  text-decoration: none;
}
.alertify .alertify-buttons .alertify-button-ok.disabled {
  background-color: #dedede;
  color: #585858;
  cursor: default;
}
.alertify .alertify-buttons .alertify-button-ok.disabled:hover {
  background-color: #dedede;
  color: #585858;
}
.shorten .shorten-link {
  display: inline-block;
  font-weight: bold;
  position: relative;
}
.shorten .shorten-link:after {
  border: 8px solid transparent;
  border-top-color: #32a039;
  content: " ";
  display: inline-block;
  height: 0;
  position: absolute;
  right: -18px;
  top: 6px;
  width: 0;
}
.shorten .shorten-link.shorten-less:after {
  border-top-color: transparent;
  border-bottom-color: #32a039;
  top: -2px;
}
.prescription-content p.lead {
  font-size: 1.2em;
}
.prescription-content form .field.person-detail-field {
  margin: .333em 0;
}
.prescription-content .button.start-prescription-order {
  margin-top: 0.5em;
}
.prescription-content .actions button,
.prescription-content .actions .button,
.prescription-content .actions input[type="submit"] {
  margin: 0.83333333em 0;
}
.prescription-content .prescription-how-to-list {
  list-style: none;
  counter-reset: list-counter;
  margin: 10px 0 0 0;
  padding: 0;
}
.prescription-content .prescription-how-to-list li {
  padding-left: 50px;
  position: relative;
  min-height: 50px;
  display: block;
  margin-top: 0;
  margin-bottom: 1.3em;
}
.prescription-content .prescription-how-to-list li:before {
  position: absolute;
  left: 0;
  top: 0;
  content: counter(list-counter);
  counter-increment: list-counter;
  color: #ccc;
  font-size: 3em;
  font-weight: bold;
  line-height: 1;
  margin-left: .2em;
}
.prescription-content .prescription-how-to-list .step-header {
  display: block;
  margin-bottom: .15em;
  text-transform: uppercase;
}
.prescription-content .prescription-search {
  margin-bottom: 2em;
}
.prescription-content .prescription-search .field label {
  width: 100%;
  margin-bottom: .333333em;
}
.prescription-content .prescription-search .field input {
  width: 100%;
  height: 3em;
  padding: .333333em 1em;
  font-size: 1.2em;
}
.prescription-content .prescription-search .ajax-search-results {
  position: absolute;
  background-color: #f5f5f5;
  padding: 10px 15px;
  z-index: 100;
  border: solid 1px #ddd;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15);
  width: 100%;
  box-sizing: border-box;
  max-height: 300px;
  overflow: auto;
}
.prescription-content .prescription-search .ajax-search-results ul {
  list-style-type: none;
  margin: 0;
}
.prescription-content .prescription-search .ajax-search-results li:hover {
  text-decoration: underline;
}
.prescription-content .prescription-search .ajax-search-results li.selected {
  font-weight: bold;
}
.prescription-content .prescription-search .ajax-search-results li {
  cursor: pointer;
  margin: 6px 0;
}
.prescription-content .prescription-search .ajax-search-results li + li {
  padding-top: 5px;
  border-top: 1px solid #ddd;
}
.prescription-content .prescription-search .ajax-search-results li .no-results {
  font-style: italic;
}
.prescription-content .prescription-secondary-field {
  padding: 15px;
  border: 1px solid #eee;
  background: #f5f5f5;
  box-sizing: border-box;
}
.prescription-content .prescription-secondary-field .prescription-collapse-textarea {
  margin-top: 1em;
  display: none;
}
.prescription-content .prescription-secondary-field .prescription-collapse-textarea .field label {
  width: 100%;
}
.prescription-content .prescription-secondary-field .prescription-collapse-textarea textarea {
  width: 100%;
}
.prescription-content .basket-table.prescription-table.empty {
  opacity: 0.6;
}
.prescription-content .basket-table.prescription-table thead th {
  font-size: 1em;
}
.prescription-content .basket-table.prescription-table .delete {
  margin: 0;
  font-size: 1em;
}
.prescription-content .basket-table.prescription-table .image-td img {
  max-width: 125px;
}
@media only screen and (max-width: 420px), only screen and (max-device-width: 700px) {
  .prescription-content .basket-table.prescription-table .image-td {
    width: 30px !important;
  }
  .prescription-content .basket-table.prescription-table .image-td img {
    max-width: 100%;
  }
}
@media only screen and (max-width: 420px), only screen and (max-device-width: 700px) {
  .prescription-content .basket-table.prescription-table select {
    min-width: 40px !important;
  }
}
.prescription-order-phases {
  margin-top: 35px;
}
.prescription-order-phases ul {
  margin-left: 0;
}
.prescription-order-phases-item {
  display: inline-block;
  margin-right: 3px;
  padding: 10px 12px;
  font-weight: bold;
  color: white;
  background-color: gray;
}
@media only screen and (max-width: 420px), only screen and (max-device-width: 700px) {
  .prescription-order-phases-item {
    display: block;
  }
}
.prescription-order-phases-item.disabled {
  background-color: #b3b3b3;
  cursor: default;
}
.prescription-order-phases-item:hover:not(.disabled) {
  background-color: #32a039;
  cursor: pointer;
}
.prescription-order-phases-item.active-phase {
  background-color: #32a039;
}
.prescription-order-phases-item.errors-in-phase {
  outline: 2px solid #c41c1c;
  outline-offset: -2px;
}
.prescription-order-phases-item .tab-number {
  margin-right: .4em;
  font-size: 1.15em;
  color: white;
  color: rgba(255, 255, 255, 0.75);
}
.prescription-order-phase {
  padding: 15px 5px 5px 5px;
}
.prescription-order-phase .tab-section {
  padding-top: 30px;
}
.prescription-order-phase.hidden {
  display: none;
}
.prescription-consent-popover {
  position: absolute;
  width: 300px;
  z-index: 1;
  margin-top: -25px;
  padding: 5px;
  background: lightgrey;
  border: solid 1px;
  text-align: center;
}
.prescription-link {
  margin-top: 0;
}
.mobile-only {
  display: none;
}
.half {
  width: 50%;
}
.header-wrap {
  margin-bottom: 3.33333333em;
}
@media (min-width: 1000px) {
  body.nav-fixed .header-wrap {
    padding-top: 80px;
  }
}
header {
  zoom: 1;
  padding: 1.66666667em 0;
  opacity: 1;
  clear: both;
}
header:before,
header:after {
  content: "";
  display: table;
}
header:after {
  clear: both;
}
@media (min-width: 1000px) {
  body.nav-fixed header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid #dadada;
    background: #fff;
    z-index: 2000;
  }
}
header a {
  float: left;
  display: block;
  text-decoration: none;
}
header a.logo {
  zoom: 1;
  color: #2c2c2c;
  line-height: 1;
  margin-top: 0.33333333em;
}
header a.logo:before,
header a.logo:after {
  content: "";
  display: table;
}
header a.logo:after {
  clear: both;
}
header a.logo:hover {
  text-decoration: none;
}
header a.logo span {
  display: inline-block;
  vertical-align: middle;
}
header a.logo .image img {
  display: block;
}
header a.logo .name {
  text-transform: uppercase;
  font-size: 1.66666667em;
  font-family: "DIN Pro", arial, helvetica, sans-serif;
  font-weight: bold;
}
header a.logo .name span {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-left: 0.83333333em;
  padding-left: 0.83333333em;
  border-left: 1px solid #dadada;
}
header #basket {
  float: right;
  background: transparent url(../../icons/basket.png) no-repeat scroll left center;
  color: #575757;
  padding-left: 2.5em;
}
header #basket:hover {
  text-decoration: none;
}
header #basket:hover .column-2 span {
  text-decoration: underline;
}
header #basket .column {
  display: inline-block;
  vertical-align: middle;
  padding: 0 1.25em;
}
header #basket .column:first-child {
  border-right: 1px solid #dadada;
}
header #basket .column:last-child {
  padding-right: 0;
}
header #basket .column .title {
  font-weight: bold;
  color: #32a039;
  display: block;
}
.banner-carousel {
  margin-top: 0.83333333em;
  border: none;
  box-shadow: none;
}
.banner-carousel .slides {
  height: 150px;
}
.banner-carousel .slides li {
  margin: 0;
}
.banner-carousel .flex-control-paging {
  bottom: -2px;
  z-index: 100;
}
#sidebar {
  float: left;
  width: 20.83333333em;
}
#sidebar .prescription-btn {
  color: #fff;
  background: #424242;
  margin: 0;
  padding: 1em;
  padding-top: 1.1em;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 1.25em;
}
#sidebar .prescription-btn:hover,
#sidebar .prescription-btn:focus {
  background: #5c5c5c;
}
#sidebar #product-search {
  position: relative;
  z-index: 2;
}
#sidebar #product-search input[type=text] {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #b6b6b6;
  border-width: 1px 0;
  font-family: "DIN Pro", arial, helvetica, sans-serif;
  font-weight: bold;
  font-size: 1.33333333em;
  line-height: 2.16666667em;
  height: 2.16666667em;
  width: 100%;
}
#sidebar #product-search input[type=submit] {
  font-size: 1.33333333em;
  background: white url(/static/icons/magnifier.png) no-repeat scroll center center;
  height: 2.16666667em;
  line-height: 8.33333333em;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 2.66666667em;
  position: absolute;
  right: 0;
  top: 0;
}
#sidebar .menu-container {
  position: relative;
  z-index: 2;
  list-style: none;
  margin: 0;
  padding: 0;
}
#sidebar .menu-container > ul {
  margin: 0;
}
#sidebar .menu-container ul {
  list-style: none;
}
#sidebar .menu-container li {
  font-family: "DIN Pro", arial, helvetica, sans-serif;
  width: 90%;
  font-size: 1em;
  margin: 0.16666667em 0;
  text-transform: uppercase;
}
#sidebar .menu-container li a {
  padding: 0;
  color: #2c2c2c;
}
#sidebar .menu-container .menu-header {
  color: #32a039;
  font-size: 1.66666667em;
  font-family: "DIN Pro", arial, helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0.41666667em 0;
}
#sidebar .menu-container li.active > a {
  font-weight: bold;
}
#sidebar .menu-container #product-menu hr {
  margin: 10px 0;
}
#sidebar .menu-container .pages-container {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #f4f4f4;
  padding: .5em 1em;
  margin-top: 1.5em;
}
@media only screen and (max-width: 420px), only screen and (max-device-width: 700px) {
  #sidebar .menu-container .pages-container {
    padding: 0;
    margin: 0;
    background: transparent;
  }
}
#sidebar .menu-container .pages-container ul {
  margin: 0;
}
#content {
  float: right;
  width: 56.66666667em;
}
footer {
  margin-top: 3.33333333em;
  background: #f4f4f4;
  border-top: 1px solid #dadada;
  padding-top: 1.66666667em;
}
footer section {
  zoom: 1;
  width: 80em;
  margin: 0 auto;
  margin-top: 1.66666667em;
  padding-bottom: 1.66666667em;
}
footer section:before,
footer section:after {
  content: "";
  display: table;
}
footer section:after {
  clear: both;
}
footer section a {
  color: #424242;
}
footer section .column {
  display: inline-block;
  vertical-align: top;
}
footer section .column.column-1 {
  width: 23.33333333em;
}
footer section .column.column-2 {
  width: 34.16666667em;
}
footer section .column.column-3 {
  width: 21.66666667em;
}
footer section .column p:last-child {
  margin-bottom: 0;
}
footer section .divider {
  color: #575757;
}
#user-toolbar {
  font-family: "DIN Pro", arial, helvetica, sans-serif;
  background: black;
  color: white;
  padding: 0.83333333em 0;
  text-transform: uppercase;
}
#user-toolbar .column {
  width: 50%;
}
#user-toolbar .column-1 {
  float: left;
}
#user-toolbar .column-2 {
  float: right;
  text-align: right;
}
#user-toolbar a {
  color: white;
}
.user-header-login {
  display: inline-block;
  position: relative;
  margin-left: 20px;
  padding-top: 0.83333333em;
}
@media only screen and (max-width: 420px), only screen and (max-device-width: 700px) {
  .user-header-login {
    display: block;
    margin-top: 10px;
    margin-left: 0;
  }
}
.user-header-login.open .dropdown-pane {
  display: block;
}
.user-header-login * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.user-header-login .dropdown-toggle i {
  margin-right: 3px;
  font-size: 14px;
}
.user-header-login .dropdown-toggle:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-left: 3px;
  margin-bottom: 3px;
  border-top: 4px solid #32a039;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}
.user-header-login .dropdown-pane {
  position: absolute;
  background-color: white;
  display: none;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  padding: 10px;
  width: 240px;
  right: 0;
  z-index: 100;
  text-align: center;
  top: 100%;
  margin-top: 5px;
}
.user-header-login .dropdown-pane:before {
  content: "";
  position: absolute;
  bottom: 100%;
  right: 19px;
  border-bottom: 7px solid #cdcdcd;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  z-index: 2;
}
.user-header-login .dropdown-pane:after {
  content: "";
  position: absolute;
  bottom: 100%;
  right: 20px;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  z-index: 3;
}
@media only screen and (max-width: 420px), only screen and (max-device-width: 700px) {
  .user-header-login .dropdown-pane {
    right: auto;
    left: 0;
  }
  .user-header-login .dropdown-pane:before {
    right: auto;
    left: 19px;
  }
  .user-header-login .dropdown-pane:after {
    right: auto;
    left: 20px;
  }
}
.user-header-login a.button {
  margin: auto;
  width: 100%;
  background-color: #32a039;
  color: white;
}
.language-chooser {
  text-align: right;
  padding-top: 0.83333333em;
  display: inline-block;
}
.language-chooser form {
  padding: 0;
  margin: 0;
}
.language-chooser form button {
  display: inline-block;
  background-image: none;
  background-position: left center !important;
  background-repeat: no-repeat !important;
  padding: 0 0 0 20px;
  margin: 0;
  font-size: 1em;
  font-family: inherit;
  background: none;
  text-transform: none;
  font-weight: normal;
}
.language-chooser form button + button {
  margin: 0 0 0 20px;
}
.language-chooser form button:hover {
  text-decoration: underline;
}
.language-chooser form button.language-fi {
  background-image: url(../img/icons/fi.png);
}
.language-chooser form button.language-sv {
  background-image: url(../img/icons/sv.png);
}
.language-chooser form button.selected {
  opacity: .5;
  font-weight: bold;
  cursor: default;
}
.language-chooser form button.selected:hover {
  text-decoration: none;
}
.menu {
  font-family: "DIN Pro", arial, helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  margin-left: 0;
  padding: 0;
  text-transform: uppercase;
}
.menu li {
  list-style: none;
  margin: 0;
}
.menu li.active > .children,
.menu li.ancestor > .children {
  border-left: 2px solid #32a039;
  display: list-item;
  list-style: none;
  padding-left: 1.25em;
}
.menu li.level-0.active,
.menu li.level-0.ancestor {
  margin-bottom: 1.66666667em;
}
.menu li a {
  color: #424242;
  display: block;
  padding: 0.08333333em 0;
}
.menu li a:hover {
  color: #32a039;
}
.menu li a.active {
  color: #32a039;
  font-family: "DIN Pro", arial, helvetica, sans-serif;
  font-weight: bold;
}
.menu li .children {
  display: none;
  margin-left: 0;
}
.breadcrumb {
  zoom: 1;
  margin-bottom: 1.25em;
  margin-left: 0;
}
.breadcrumb:before,
.breadcrumb:after {
  content: "";
  display: table;
}
.breadcrumb:after {
  clear: both;
}
.breadcrumb li {
  display: inline-block;
  list-style: none;
}
.breadcrumb li a {
  color: #424242;
}
.breadcrumb li .divider {
  display: inline-block;
  padding: 0 0.41666667em;
}
.shop.index #content-contact {
  position: relative;
}
.shop.index #content-contact p:last-child {
  margin-bottom: 0;
}
.shop.index #content-contact .border {
  position: absolute;
  left: 32.91666667em;
}
.shop.index #content-contact .description {
  float: left;
  width: 30.83333333em;
}
.shop.index #content-contact .contact {
  float: right;
  width: 21.66666667em;
}
.shop.index .showcase.right-column .product-wrap {
  flex: 0 0 100%;
}
.shop.index .showcase.center .product-wrap {
  flex: 0 0 33.33%;
  max-width: 33.33%;
}
.shop.index .showcase > .row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -0.83333333em;
}
.shop.index .showcase .product-wrap {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0.83333333em;
}
.shop.index .showcase .product {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0.83333333em;
  border: 1px solid #dadada;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  height: 100%;
}
.shop.index .showcase .product .image {
  display: block;
  width: 100%;
  min-height: 166px;
}
.shop.index .showcase .product .image .product-image {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.shop.index .showcase .product .image img {
  max-width: 100%;
}
.shop.index .showcase .product .image .ribbon {
  max-width: 70%;
}
.shop.index .showcase .product .info {
  width: 100%;
  display: block;
  margin-top: 0.83333333em;
}
.shop.index .showcase .product .name {
  width: 100%;
  display: block;
  font-weight: bold;
}
.product-image {
  position: relative;
  line-height: 0;
}
.product-image .ribbon {
  position: absolute;
  right: 0;
  bottom: 0;
}
.articles {
  margin-top: 1.66666667em;
}
.articles article {
  zoom: 1;
}
.articles article:before,
.articles article:after {
  content: "";
  display: table;
}
.articles article:after {
  clear: both;
}
.articles article .article-content {
  float: right;
  width: 44.16666667em;
}
.articles article .article-content h3 {
  font-size: 1em;
  color: #424242;
  line-height: 1.66666667em;
  font-family: arial, helvetica, sans-serif;
  text-transform: none;
  font-weight: bold;
  margin-bottom: 0;
}
.articles article .article-content h3 .date {
  margin-left: 0.83333333em;
}
.google-maps {
  width: 100%;
  height: 260px;
  margin: 0 0 2.5em 0;
}
.subcategories {
  margin: 1.66666667em 0;
}
.subcategories li {
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  margin: 0 1.25em 0.41666667em 0;
}
.subcategories li a {
  color: #32a039;
  font-size: 1.33333333em;
  font-family: "DIN Pro", arial, helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}
.product-row {
  border-top: 1px solid #dadada;
  padding: 2.5em 0;
}
.product-row .column {
  display: inline-block;
  vertical-align: top;
}
.product-row .column.image {
  width: 15%;
  text-align: center;
  height: 10.16666667em;
  position: relative;
}
.product-row .column.image img {
  margin: 0 auto;
}
.product-row .column.image .ribbon {
  width: 100%;
}
.product-row .column.image .badge {
  display: block;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.product-row .column.details {
  width: 55%;
  padding-left: 2.5em;
}
.product-row .column.details .name {
  color: #32a039;
  font-size: 1.33333333em;
  font-family: "DIN Pro", arial, helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
}
.product-row .column.details .more {
  font-weight: bold;
}
.product-row .column.buy {
  width: 23%;
}
.product-row .column.buy .price {
  color: #32a039;
  font-size: 1.33333333em;
  font-family: "DIN Pro", arial, helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}
.product-row .column.buy .price.discount {
  color: #ff7518;
}
.product-row .column.buy input {
  font-size: 1.16666667em;
  padding: 0.75em 0.66666667em 0.58333333em 0.66666667em;
  margin-bottom: 0;
  line-height: 1;
}
.product-row .column .column-title .name {
  display: inline-block;
}
.normal-price {
  text-decoration: line-through;
  color: #999;
}
.shop.product {
  zoom: 1;
}
.shop.product:before,
.shop.product:after {
  content: "";
  display: table;
}
.shop.product:after {
  clear: both;
}
.shop.product .product-info {
  float: left;
  width: 21.66666667em;
}
.shop.product .product-info img {
  margin: 0 auto;
  display: block;
}
.shop.product .product-info .no-image {
  line-height: 1.66666667em;
}
.shop.product .product-info .row {
  border-top: 1px solid #dadada;
}
.shop.product .product-info .row.border-bottom {
  border-bottom: 1px solid #dadada;
}
.shop.product .product-info .row .cell {
  padding: 1.66666667em 0;
  display: inline-block;
  vertical-align: middle;
  width: 50%;
}
.shop.product .product-info .row .cell.prices {
  text-align: right;
}
.shop.product .product-info .row .cell input {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.shop.product .product-info .row .cell label {
  color: #32a039;
  font-size: 1.16666667em;
  font-family: "DIN Pro", arial, helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  color: #2c2c2c;
}
.shop.product .product-info .price {
  color: #32a039;
  font-size: 1.33333333em;
  font-family: "DIN Pro", arial, helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}
.shop.product .not-orderable {
  display: block;
  padding: 1.66666667em 0;
  border-bottom: 1px solid #dadada;
  margin-bottom: 1.66666667em;
}
.shop.product .description {
  float: right;
  width: 30.83333333em;
}
.shop.product .description p:last-child a::after {
  content: " »";
}
.basket .confirm-title-row {
  border-bottom: 1px solid #32a039;
  margin-bottom: 2em;
}
.basket .confirm-title-row h1.confirm-title {
  float: left;
  margin-top: .4em;
}
.basket .confirm-title-row .upper-confirm-button {
  float: right;
}
.basket .confirm-title-row .upper-confirm-button form {
  margin-bottom: 0;
}
.basket .confirm-title-row .upper-confirm-button input[type="submit"] {
  margin-top: 0;
}
.basket h2.order-reference-number {
  color: #424242;
  text-transform: none;
}
.basket .payment-providers form {
  margin: 0;
  padding: 5px;
  float: left;
}
.basket .payment-providers button {
  width: 120px;
  height: 100px;
  margin: 0;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.basket .payment-providers button:hover,
.basket .payment-providers button:focus {
  background: #e6e6e6;
}
.basket .payment-providers img {
  max-width: 100%;
  max-height: 100%;
}
.tax-report-table th {
  font-size: 1em;
}
.basket-table .delete {
  background-color: #424242;
  border: none;
  color: #ffffff;
  display: inline-block;
  font-family: "DIN Pro", arial, helvetica, sans-serif;
  font-size: 1.16666667em;
  font-weight: bold;
  margin: 1.66666667em 0;
  padding: 0.66666667em 0.83333333em;
  text-align: center;
  text-transform: uppercase;
}
.basket-table .delete:hover,
.basket-table .delete:active {
  color: #ffffff;
  background-color: #000000;
  text-decoration: none;
}
.basket-table .delete.disabled {
  background-color: #dedede;
  color: #585858;
  cursor: default;
}
.basket-table .delete.disabled:hover {
  background-color: #dedede;
  color: #585858;
}
.basket-table .method-line td {
  border: none;
  padding-top: 1.66666667em;
  padding-bottom: 0;
  font-family: "DIN Pro", arial, helvetica, sans-serif;
  font-weight: bold;
  font-size: 1em;
}
.basket-table .method-line td .price {
  font-family: "DIN Pro", arial, helvetica, sans-serif !important;
  font-weight: bold;
}
.basket-table th {
  font-size: 1em;
}
.basket-table td {
  vertical-align: top;
}
.basket-table td .product-name {
  color: #32a039;
  font-size: 1.33333333em;
  font-family: "DIN Pro", arial, helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}
.basket-table td .price {
  color: #32a039;
  font-size: 1.33333333em;
  font-weight: bold;
  text-transform: uppercase;
  color: #424242;
  font-family: "DIN Pro", arial, helvetica, sans-serif;
}
.basket-table td .price.line-total {
  color: #32a039;
  font-family: "DIN Pro", arial, helvetica, sans-serif;
  font-weight: bold;
}
.basket-table td:first-child,
.basket-table th:first-child {
  padding-left: 0;
}
.basket-table td:last-child,
.basket-table th:last-child {
  padding-right: 0;
}
.basket-table tbody tr:last-child td {
  border-color: #32a039;
}
.basket-table .method-line + .method-line td {
  padding-top: 0.83333333em !important;
}
.basket-table tfoot tr:first-child td {
  padding-top: 1.66666667em;
}
.basket-table tfoot tr td {
  border-color: #32a039;
  vertical-align: middle;
}
.basket-table tfoot tr td input {
  margin: 0;
}
.basket-table tfoot tr td.total,
.basket-table tfoot tr td.total-label {
  color: #32a039;
  font-size: 1.33333333em;
  font-family: "DIN Pro", arial, helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}
.basket-table tfoot tr td small {
  font-weight: normal;
  font-size: 0.83333333em;
  display: block;
}
.auth header .banner {
  display: none;
}
.auth #sidebar {
  display: none;
}
.auth #content {
  float: none;
  margin: 0 auto;
  width: 23.33333333em;
}
@media only screen and (max-width: 420px), only screen and (max-device-width: 700px) {
  #alertifycover,
  .alertify {
    display: none;
  }
  .row {
    zoom: 1;
    border-top: 1px solid #dadada;
  }
  .row:before,
  .row:after {
    content: "";
    display: table;
  }
  .row:after {
    clear: both;
  }
  .row .cell {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    display: block;
    padding: 0.83333333em 0;
  }
  .row .cell.prices {
    text-align: center;
  }
  .row .cell.prices .price {
    font-size: 1.83333333em;
  }
  .mobile-only {
    display: block;
  }
  .desktop-only {
    display: none !important;
  }
  .header-wrap {
    margin: 0;
  }
  header {
    position: relative;
    margin: 0;
    padding: 1.66666667em 0;
  }
  header a.logo {
    width: 100%;
  }
  header a.logo .image {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 30%;
  }
  header a.logo .image img {
    width: 100%;
    height: auto;
  }
  header a.logo .name {
    width: 65%;
    font-size: 1.16666667em;
  }
  .banner-carousel {
    display: none;
  }
  img {
    max-width: 100%;
  }
  .container,
  footer {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 0.83333333em;
    width: 100%;
    float: none;
  }
  #content,
  #sidebar {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 0em;
    width: 100%;
    float: none;
  }
  #sidebar .highlight {
    display: none;
  }
  footer section {
    width: 100%;
  }
  footer .column {
    width: 100% !important;
  }
  header #basket {
    bottom: -2.5em;
    height: 2.08333333em;
    line-height: 2.08333333em;
    margin: 0;
    max-width: 20%;
    padding-left: 3.33333333em;
    position: absolute;
    right: 0.83333333em;
  }
  header #basket .quiet,
  header #basket .column-2,
  header #basket .text {
    display: none;
  }
  header #basket .column-1 {
    border: none !important;
    padding: 0;
  }
  header #basket .column-1 .count {
    color: #424242;
  }
  #sidebar .prescription-btn {
    width: auto;
    margin-bottom: 1em;
  }
  #sidebar #product-search {
    width: 70%;
  }
  .leaf-node .menu-container,
  .product .menu-container,
  .basket .menu-container,
  .content-page .menu-container,
  .article-index .menu-container,
  .contact .menu-container,
  .feedback .menu-container,
  .shop.search .menu-container {
    display: none;
  }
  .shop.index .menu .level-0 {
    display: list-item;
  }
  .menu li {
    display: none;
  }
  .menu li.active,
  .menu li.ancestor {
    display: list-item;
  }
  .menu li.active > .children,
  .menu li.ancestor > .children {
    display: list-item;
  }
  .menu li.active > .children li,
  .menu li.ancestor > .children li {
    display: list-item;
  }
  #page-menu,
  #product-menu {
    display: none;
  }
  .category #product-menu {
    display: block;
  }
  .category .product-menu-header a {
    color: #32a039 !important;
  }
  .menu-container {
    margin-bottom: 2em !important;
  }
  .menu-container > ul:not(#product-menu) > li,
  .menu-container .pages-container > ul > li {
    font-size: 1.5em !important;
    font-weight: bold !important;
    margin: 0.41666667em 0 !important;
  }
  .menu-container > ul:not(#product-menu) > li a,
  .menu-container .pages-container > ul > li a {
    line-height: 1em !important;
  }
  .menu-container > ul:not(#product-menu) > li.active,
  .menu-container .pages-container > ul > li.active,
  .menu-container > ul:not(#product-menu) > li.active a,
  .menu-container .pages-container > ul > li.active a {
    background: none !important;
    color: #32a039 !important;
    text-decoration: none !important;
  }
  .menu-container > ul:not(#product-menu) > li.active a,
  .menu-container .pages-container > ul > li.active a {
    padding: 0 !important;
    margin: 0 !important;
  }
  .menu-container > ul:not(#product-menu) > li.mobile-only-on-index,
  .menu-container .pages-container > ul > li.mobile-only-on-index {
    display: none;
  }
  body.index .menu-container > ul:not(#product-menu) > li.mobile-only-on-index,
  body.index .menu-container .pages-container > ul > li.mobile-only-on-index {
    display: list-item;
  }
  .menu-container a {
    color: #575757;
  }
  .menu-container .active,
  .menu-container .active > a {
    background: none !important;
    color: #32a039 !important;
    text-decoration: none !important;
  }
  .breadcrumb {
    display: none;
  }
  .history-navigation {
    border-bottom: 1px solid #dadada;
    margin-top: 0.83333333em;
    margin-bottom: 1.66666667em;
    color: #32a039;
    font-size: 1.16666667em;
    font-family: "DIN Pro", arial, helvetica, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
  }
  .shop.index .history-navigation {
    display: none;
  }
  .history-navigation a {
    display: block;
    padding: 0.83333333em 0;
  }
  .shop.index #page-menu li {
    display: list-item;
  }
  .shop.index #content-contact p:last-child {
    margin-bottom: 1.66666667em;
  }
  .shop.index #content-contact .description,
  .shop.index #content-contact .contact {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 0em;
    width: 100%;
    float: none;
  }
  .shop.index .showcase > .row {
    border: 0;
  }
  .shop.index .showcase.right-column .product-wrap {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .shop.index .showcase.center .product-wrap {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .shop.index .articles {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 0em;
    width: 100%;
    float: none;
  }
  .shop.index .description {
    border: none;
  }
  .articles article img {
    margin: 0 10px 10px 0;
  }
  .articles article .article-content {
    width: 100%;
    float: none;
  }
  .shop.category.leaf-node .category-description h1,
  .shop.search.leaf-node .category-description h1 {
    display: block;
  }
  .shop.category .category-description h1,
  .shop.search .category-description h1 {
    display: none;
  }
  .shop.category .product-row,
  .shop.search .product-row {
    padding: 1.66666667em 0;
  }
  .shop.category .product-row .image,
  .shop.search .product-row .image,
  .shop.category .product-row .details,
  .shop.search .product-row .details,
  .shop.category .product-row .buy,
  .shop.search .product-row .buy {
    display: block;
  }
  .shop.category .product-row .image,
  .shop.search .product-row .image {
    float: left;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 30%;
    padding-right: 1.66666667em;
  }
  .shop.category .product-row .image .badge,
  .shop.search .product-row .image .badge {
    width: auto;
    position: relative;
  }
  .shop.category .product-row .details,
  .shop.search .product-row .details {
    float: left;
    width: 70%;
    padding: 0;
    margin-bottom: 1.66666667em;
  }
  .shop.category .product-row .buy,
  .shop.search .product-row .buy {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    zoom: 1;
    width: 70%;
    margin: 0 0 0 30%;
    display: block;
  }
  .shop.category .product-row .buy:before,
  .shop.search .product-row .buy:before,
  .shop.category .product-row .buy:after,
  .shop.search .product-row .buy:after {
    content: "";
    display: table;
  }
  .shop.category .product-row .buy:after,
  .shop.search .product-row .buy:after {
    clear: both;
  }
  .shop.category .product-row .buy .prices,
  .shop.search .product-row .buy .prices {
    float: left;
    text-align: left;
  }
  .shop.category .product-row .buy form,
  .shop.search .product-row .buy form {
    float: right;
  }
  .shop.category .product-row .buy form input,
  .shop.search .product-row .buy form input {
    float: none;
    margin: 0;
  }
  .shop.product .product-info {
    width: 100%;
  }
  .shop.product .product-info .product-image {
    float: left;
    width: 45%;
    height: auto;
    margin: 0;
  }
  .shop.product .product-info .row {
    border: none !important;
    float: right;
    width: 45%;
  }
  .shop.product .product-info .row .cell {
    float: none;
    width: 100%;
    text-align: center !important;
    padding: 0.83333333em 0;
  }
  .shop.product .product-info .row .cell.prices {
    text-align: center;
  }
  .shop.product .product-info .row .cell.prices .price {
    font-size: 1.83333333em;
  }
  .shop.product .product-info form {
    float: right;
    width: 50%;
  }
  .shop.product .product-info form .row {
    float: none;
    width: auto;
  }
  .shop.product .product-info form label {
    display: block;
  }
  .shop.product .product-info form input[type=submit] {
    font-size: 1.5em;
  }
  .shop.product .description {
    width: 100%;
  }
  .shop.basket .row-1 .cell.column-1 {
    width: 15%;
  }
  .shop.basket .row-1 .cell.column-2 {
    width: 60%;
    padding-left: 1.66666667em;
  }
  .shop.basket .row-1 .cell.column-3 {
    width: 25%;
  }
  .shop.basket .row-1 .cell .product-name {
    color: #32a039;
    font-size: 1.33333333em;
    font-family: "DIN Pro", arial, helvetica, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
  }
  .shop.basket .row-2 {
    border-top: none;
  }
  .shop.basket .row-2 .cell {
    width: 33.33333333%;
    text-align: center;
  }
  .shop.basket .row-2 .cell .header {
    color: #32a039;
    font-size: 1.16666667em;
    font-family: "DIN Pro", arial, helvetica, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    color: #424242;
    display: block;
    margin-bottom: 0.55555556em;
  }
  .shop.basket .row-2 .cell .price {
    font-family: "DIN Pro", arial, helvetica, sans-serif;
  }
  .shop.basket .row-2 .cell select {
    width: auto;
  }
  .shop.basket .row-2 .cell.column-3 .price {
    font-family: "DIN Pro", arial, helvetica, sans-serif;
    font-weight: bold;
    color: #32a039;
  }
  .shop.basket .method-line .cell {
    color: #32a039;
    font-size: 1.16666667em;
    font-family: "DIN Pro", arial, helvetica, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    text-align: right;
    text-transform: none;
    color: #424242;
  }
  .shop.basket .method-line .cell.column-1 {
    width: 85%;
  }
  .shop.basket .method-line .cell.column-2 {
    width: 15%;
  }
  .shop.basket .tfoot .row {
    border: 1px solid #32a039;
    border-width: 1px 0;
  }
  .shop.basket .tfoot .row .cell.column-1 {
    width: 30%;
  }
  .shop.basket .tfoot .row .cell.column-1 button {
    max-width: 100%;
    margin: 0;
  }
  .shop.basket .tfoot .row .cell.column-2 {
    float: right;
    color: #32a039;
    font-size: 1.33333333em;
    font-family: "DIN Pro", arial, helvetica, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    text-align: right;
    text-transform: none;
    width: 70%;
    line-height: 3.33333333em;
  }
  .shop.basket form label {
    display: block;
    width: auto;
  }
  .shop.basket form input[type=text],
  .shop.basket form select,
  .shop.basket form textarea {
    width: 100% !important;
  }
  .shop.basket form .checkbox-input label {
    display: inline;
    width: auto;
  }
  .shop.basket form .field.radio-select ul {
    margin-left: 0 !important;
    width: 100% !important;
  }
  .shop.page .menu-container h2 {
    display: none;
  }
}
