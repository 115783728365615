.prescription-content {
    p.lead {
        font-size: 1.2em;
    }

    form .field.person-detail-field {
        margin: .333em 0;
    }

    .button.start-prescription-order {
        margin-top: 0.5em;
    }

    .actions {
        button,
        .button,
        input[type="submit"] {
            margin: ((@baseline / @em) / 2) 0;
        }
    }

    .prescription-how-to-list {
        list-style: none;
        counter-reset: list-counter;
        margin: 10px 0 0 0;
        padding: 0;

        li {
            padding-left: 50px;
            position: relative;
            min-height: 50px;
            display: block;
            margin-top: 0;
            margin-bottom: 1.3em;
            &:before {
                position: absolute;
                left: 0;
                top: 0;
                content: counter(list-counter);
                counter-increment: list-counter;
                color: #ccc;
                font-size: 3em;
                font-weight: bold;
                line-height: 1;
                margin-left: .2em;
            }
        }
        .step-header {
            display: block;
            margin-bottom: .15em;
            text-transform: uppercase;
        }
    }

    .prescription-search {
        margin-bottom: 2em;
        .field {
            label {
                width: 100%;
                margin-bottom: .333333em;
            }
            input {
                width: 100%;
                height: 3em;
                padding: .333333em 1em;
                font-size: 1.2em;
            }
        }
        .ajax-search-results {
            position: absolute;
            background-color: #f5f5f5;
            padding: 10px 15px;
            z-index: 100;
            border: solid 1px #ddd;
            box-shadow: 0px 2px 3px rgba(0,0,0,.15);
            width: 100%;
            box-sizing: border-box;
            max-height: 300px;
            overflow: auto;

            ul {
                list-style-type: none;
                margin: 0;
            }

            li:hover {
                text-decoration: underline;
            }

            li.selected {
                font-weight: bold;
            }

            li {
                cursor: pointer;
                margin: 6px 0;

                & + li {
                    padding-top: 5px;
                    border-top: 1px solid #ddd;
                }

                .no-results {
                    font-style: italic;
                }
            }
        }
    }

    .prescription-secondary-field {
        padding: 15px;
        border: 1px solid #eee;
        background: #f5f5f5;
        box-sizing: border-box;
        .prescription-collapse-textarea {
            margin-top: 1em;
            display: none;
            .field label {
                width: 100%;
            }
            textarea {
                width: 100%;
            }
        }
    }

    .basket-table.prescription-table {
        &.empty {
            opacity: 0.6;
        }
        thead th {
            font-size: 1em;
        }
        .delete {
            margin: 0;
            font-size: 1em;
        }
        .image-td {
            img {
                max-width: 125px;
            }
            @media only screen and (max-width:420px), only screen and (max-device-width:700px) {
                width: 30px !important;

                img {
                    max-width: 100%;
                }
            }
        }
        select {
            @media only screen and (max-width:420px), only screen and (max-device-width:700px) {
                min-width: 40px !important;
            }
        }
    }
}

.prescription-order-phases {
    margin-top: 35px;

    ul {
        margin-left: 0;
    }

    &-item {
        display: inline-block;
        margin-right: 3px;
        padding: 10px 12px;
        font-weight: bold;
        color: white;
        background-color: gray;

        @media only screen and (max-width:420px), only screen and (max-device-width:700px) {
            display: block;
        }

        &.disabled {
            background-color: lighten(gray, 20%);
            cursor: default;
        }

        &:hover:not(.disabled) {
            background-color: @brand-green;
            cursor: pointer;
        }

        &.active-phase {
            background-color: @brand-green;
        }

        &.errors-in-phase {
            outline: 2px solid @text-color-error;
            outline-offset: -2px;
        }

        .tab-number {
            margin-right: .4em;
            font-size: 1.15em;
            color: white;
            color: rgba(255,255,255,0.75);
        }
    }
}

.prescription-order-phase {
    padding: 15px 5px 5px 5px;

    .tab-section {
        padding-top: 30px;
    }

    &.hidden {
        display: none;
    }
}

.prescription-consent-popover {
    position: absolute;
    width: 300px;
    z-index: 1;
    margin-top: -25px;
    padding: 5px;
    background: lightgrey;
    border: solid 1px;
    text-align: center;
}

.prescription-link {
    margin-top: 0;
}
