// @group Shorten styles
// ============================================================================
.shorten {
	.shorten-ellipses {

	}

	.shorten-link {
		display: inline-block;
		font-weight: bold;
		position: relative;

		&:after {
			border: 8px solid transparent;
			border-top-color: #32a039;
			content: " ";
			display: inline-block;
			height: 0;
			position: absolute;
			right: -18px;
			top: 6px;
			width: 0;
		}

		&.shorten-less:after {
			border-top-color: transparent;
			border-bottom-color: @brand-green;
			top: -2px;
		}
	}

	.shorten-content {

	}
}
// @end Shorten styles
// ----------------------------------------------------------------------------