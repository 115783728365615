// @group Base layout
// ============================================================================
.mobile-only {
	display: none;
}

.half {
	width: 50%;
}

.header-wrap {
	margin-bottom: (2 * @baseline) / @em;

	@media (min-width: 1000px) {
		body.nav-fixed & {
			padding-top: 80px;
		}
	}
}

header {
	.clearfix;
	padding: (@baseline / @em) 0;
	opacity: 1;
	clear: both;

	@media (min-width: 1000px) {
		body.nav-fixed & {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			box-shadow: 0px 0px 4px rgba(0,0,0,.2);
			border-bottom: 1px solid @border-color;
			background: #fff;
			z-index: 2000;
		}
	}

	a {
		.pull;
		display: block;
		text-decoration: none;

		&.logo {
			.clearfix;
			color: @text-color-dark;
			line-height: 1;
			margin-top: (4 / @em);

			&:hover {
				text-decoration: none;
			}

			span {
				display: inline-block;
				vertical-align: middle;
			}

			.image {
				img {
					display: block;
				}
			}

			.name {
				text-transform: uppercase;
				font-size: 20 / @em;
				font-family: @brand-font;
				font-weight: bold;

				span {
					.box-sizing(border-box);
					margin-left: 10 / @em;
					padding-left: 10 / @em;
					border-left: 1px solid @border-color;
				}
			}
		}
	}

	#basket {
		.pull(right);
		background: transparent url(../../icons/basket.png) no-repeat scroll left center;
		color: @text-color-light;
		// margin-top: 18 / @em;
		padding-left: 30 / @em;

		&:hover {
			text-decoration: none;

			.column-2 span {
				text-decoration: underline;
			}
		}

		.column {
			display: inline-block;
			vertical-align: middle;
			padding: 0 ((@gutter / 2) / @em);

			&:first-child {
				border-right: 1px solid @border-color;
			}

			&:last-child {
				padding-right: 0;
			}

			.title {
				font-weight: bold;
				color: @brand-green;
				display: block;
			}
		}
	}
}

.banner-carousel {
	margin-top: (0.5 * @baseline) / @em;
	border: none;
	box-shadow: none;

	.slides {
		height: 150px;

		li {
			margin: 0;
		}
	}

	.flex-control-paging {
		bottom: -2px;
		z-index: 100;
	}
}

#sidebar {
	.column(250);

	.prescription-btn {
		color: #fff;
		background: @text-color;
		margin: 0;
		padding: 1em;
		padding-top: 1.1em;  // Vertically aligns the text more nicely
		width: 100%;
		box-sizing: border-box;
		border-radius: 4px;
		font-size: 15 / @em;
		&:hover, &:focus {
			background: lighten(@text-color, 10%);
		}
	}

	#product-search {
		@height: 26;
		position: relative;
		z-index: 2;

		input[type=text] {
			.box-sizing(border-box);
			border: 1px solid @input-border-color;
			border-width: 1px 0;
			font-family: @brand-font;
			font-weight: bold;
			font-size: 16 / @em;
			line-height: @height / @em;
			// padding: (6 / @em) (30 / @em) (5 / @em) 0;
			height: @height / @em;
			width: 100%;
		}

		input[type=submit] {
			font-size: 16 / @em;
			background: white url(/static/icons/magnifier.png) no-repeat scroll center center;
			height: @height / @em;
			line-height: 100 / @em;
			margin: 0;
			overflow: hidden;
			padding: 0;
			width: 32 / @em;
			position: absolute;
			right: 0;
			top: 0;
		}
	}

	.menu-container {
		position: relative;
		z-index: 2;
		list-style: none;
		margin: 0;
		padding: 0;

		> ul {
			margin: 0;
		}

		ul {
			list-style: none;
		}

		li {
			font-family: @brand-font;
			width: 90%;
			font-size: 12 / @em;
			margin: (2 / @em) 0;
			text-transform: uppercase;

			a {
				padding: 0;
				color: @text-color-dark;
			}
		}

		.menu-header {
			.faux-title(20);
			margin: (5 / @em) 0;
		}

		li.active > a {
			font-weight: bold;
		}

		#product-menu hr {
			margin: 10px 0;
		}

		.pages-container {
			.box-sizing(border-box);
			background: @brand-gray;
			padding: .5em 1em;
			margin-top: 1.5em;

			@media only screen and (max-width:420px), only screen and (max-device-width:700px) {
				padding: 0;
				margin: 0;
				background: transparent;
			}

			ul {
				margin: 0;
			}
		}
	}

}

#content {
	.column(680, right);
}

footer {
	margin-top: (2 * @baseline) / @em;
	background: @footer-background;
	border-top: 1px solid @border-color;
	padding-top: @baseline / @em;

	section {
		.container;
		margin-top: @baseline / @em;
		padding-bottom: @baseline / @em;

		a {
			color: @text-color;
		}

		.column {
			display: inline-block;
			vertical-align: top;

			&.column-1 {
				width: (250 + @gutter) / @em;
			}

			&.column-2 {
				width: 410 / @em;
			}

			&.column-3 {
				width: 260 / @em;
			}

			p:last-child {
				margin-bottom: 0;
			}
		}

		.divider {
			color: @text-color-light;
		}
	}
}
// @end Base layout
// ----------------------------------------------------------------------------


// @group User toolbar
// ============================================================================
#user-toolbar {
	font-family: @brand-font;
	background: black;
	color: white;
	padding: ((@baseline / 2) / @em) 0;
	text-transform: uppercase;

	.column {
		width: 50%;
	}

	.column-1 {
		float: left;
	}

	.column-2 {
		float: right;
		text-align: right;
	}

	a {
		color: white;
	}
}
// @end User toolbar
// ----------------------------------------------------------------------------

// @group User login pane
// ============================================================================
.user-header-login {
	display: inline-block;
	position: relative;
	margin-left: 20px;
	padding-top: (@baseline / 2) / @em;  // Same as the language-chooser has

	@media only screen and (max-width:420px), only screen and (max-device-width:700px) {
		display: block;
		margin-top: 10px;
		margin-left: 0;
	}

	&.open .dropdown-pane {
		display: block;
	}

	* {
		.box-sizing(border-box);
	}

	.dropdown-toggle {
		i {
			margin-right: 3px;
			font-size: 14px;
		}

		&:after {
			content: "";
			display: inline-block;
			vertical-align: middle;
			margin-left: 3px;
			margin-bottom: 3px;
			border-top: 4px solid @brand-green;
			border-left: 4px solid transparent;
			border-right: 4px solid transparent;
		}
	}

	.dropdown-pane {
		position: absolute;
		background-color: white;
		display: none;
		border: 1px solid #ddd;
		box-shadow: 0px 0px 5px rgba(0,0,0,.15);
		padding: 10px;
		width: 240px;
		right: 0;
		z-index: 100;
		text-align: center;
		top: 100%;
		margin-top: 5px;

		&:before {
			content: "";
			position: absolute;
			bottom: 100%;
			right: 19px;
			border-bottom: 7px solid #cdcdcd;
			border-left: 7px solid transparent;
			border-right: 7px solid transparent;
			z-index: 2;
		}

		&:after {
			content: "";
			position: absolute;
			bottom: 100%;
			right: 20px;
			border-bottom: 6px solid #fff;
			border-left: 6px solid transparent;
			border-right: 6px solid transparent;
			z-index: 3;
		}

		@media only screen and (max-width:420px), only screen and (max-device-width:700px) {
			right: auto;
			left: 0;

			&:before {
				right: auto;
				left: 19px;
			}

			&:after {
				right: auto;
				left: 20px;
			}
		}
	}

	a.button {
		margin: auto;
		width: 100%;
		background-color: @brand-green;
		color: white;
	}
}
// @end User login pane
// ----------------------------------------------------------------------------

// @group Language chooser
// ============================================================================
.language-chooser {
	text-align: right;
	padding-top: (@baseline / 2) / @em;
	display: inline-block;

	form {
		padding: 0;
		margin: 0;

		button {
			display: inline-block;
			background-image: none;
			background-position: left center !important;
			background-repeat: no-repeat !important;
			padding: 0 0 0 20px;
			margin: 0;
			font-size: @font-size / @em;
			font-family: inherit;
			background: none;
			text-transform: none;
			font-weight: normal;

			+ button {
				margin: 0 0 0 20px;
			}

			&:hover {
				text-decoration: underline;
			}

			&.language-fi {
				background-image: url(../img/icons/fi.png);
			}

			&.language-sv {
				background-image: url(../img/icons/sv.png);
			}

			&.selected {
				// color: @text-color-disabled;
				opacity: .5;
				font-weight: bold;
				cursor: default;

				&:hover {
					text-decoration: none;
				}
			}
		}
	}
}
// @end Language chooser
// ----------------------------------------------------------------------------


// @group Navigation
// ============================================================================
.menu {
	font-family: @brand-font;
	font-style: normal;
	font-weight: normal;
	margin-left: 0;
	padding: 0;
	text-transform: uppercase;

	li {
		list-style: none;
		margin: 0;

		&.active,
		&.ancestor {
			> .children {
				border-left: 2px solid @brand-green;
				display: list-item;
				list-style: none;
				padding-left: (@gutter / 2) / @em;
			}
		}

		&.level-0 {
			&.active,
			&.ancestor {
				margin-bottom: @baseline / @em;
			}
		}

		a {
			color: @text-color;
			display: block;
			padding: (1 / @em) 0;

			&:hover {
				color: @brand-green;
			}

			&.active {
				color: @brand-green;
				font-family: @brand-font;
				font-weight: bold;
			}
		}

		.children {
			display: none;
			margin-left: 0;
		}
	}
}


// @end Navigation
// ----------------------------------------------------------------------------


// @group Breadcrumb
// ============================================================================
.breadcrumb {
	.clearfix;
	margin-bottom: (@gutter / 2) / @em;
	margin-left: 0;

	li {
		display: inline-block;
		list-style: none;

		a {
			color: @text-color;
		}

		.divider {
			display: inline-block;
			padding: 0 ((@gutter / 6) / @em);
		}
	}
}
// @end Breadcrumb
// ----------------------------------------------------------------------------


// @group Index page
// ============================================================================

.shop.index {
	#content-contact {
		position: relative;

		p:last-child {
			margin-bottom: 0;
		}

		.border {
			position: absolute;
			left: 395 / @em;
		}

		.description {
			.column(370);
			// border-right: 1px solid @border-color;
			// padding-right: (@gutter - 5) / @em;
		}

		.contact {
			.column(260, right);
		}
	}

	.showcase {
		&.right-column {
			.product-wrap {
				flex: 0 0 100%;
			}
		}

		&.center {
			.product-wrap {
				flex: 0 0 33.33%;
				max-width: 33.33%;
			}
		}

		> .row {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			margin: (-10 / @em);
		}

		.product-wrap {
			.box-sizing(border-box);
			padding: (10 / @em);
		}

		.product {
			.box-sizing(border-box);
			padding: (10 / @em);
			border: 1px solid @border-color;
			text-align: center;
			display: flex;
			flex-wrap: wrap;
			align-content: space-between;
			height: 100%;

			.image {
				display: block;
				width: 100%;
				min-height: 166px;

				.product-image {
					display: flex;
					height: 100%;
					align-items: center;
					justify-content: center;
					align-content: center;
				}

				img {
					max-width: 100%;
				}

				.ribbon {
					max-width: 70%;
				}
			}

			.info {
				width: 100%;
				display: block;
				margin-top: (@baseline / @em) / 2;
			}

			.name {
				width: 100%;
				display: block;
				font-weight: bold;
			}
		}
	}
}

.product-image {
	position: relative;
	line-height: 0;

	.ribbon {
		position: absolute;
		right: 0;
		bottom: 0;
	}
}

// @end Index page
// ----------------------------------------------------------------------------


// @group Articles
// ============================================================================
.articles {
	margin-top: @baseline / @em;
	article {
		.clearfix;

		.article-content {
			.column(530, right);

			h3 {
				font-size: @font-size / @em;
				color: @text-color;
				line-height: @baseline / @em;
				font-family: @default-font;
				text-transform: none;
				font-weight: bold;
				margin-bottom: 0;

				.date {
					margin-left: (@gutter / 3) / @em;
				}
			}
		}
	}
}
// @end Articles
// ----------------------------------------------------------------------------


// @end Google Maps
// ----------------------------------------------------------------------------
.google-maps {
	width: 100%;
	height: 260px;
	margin: 0 0 (@gutter / @em) 0;
}
// @group Google Maps
// ============================================================================


// @group Category page
// ============================================================================
.subcategories {
	margin: (@baseline / @em) 0;

	li {
		list-style: none;
		display: inline-block;
		vertical-align: middle;
		margin: 0 ((@gutter / 2) / @em) ((@baseline / 4) / @em) 0;

		a {
			.faux-title();
		}
	}
}

.product-row {
	border-top: 1px solid @border-color;
	padding: (@gutter / @em) 0;

	.column {
		display: inline-block;
		vertical-align: top;

		&.image {
			width: 15%;
			text-align: center;
			height: 122 / @em;
			position: relative;

			img {
				margin: 0 auto;
			}

			.ribbon {
				width: 100%;
			}

			.badge {
				display: block;
				width: 100%;
				position: absolute;
				bottom: 0;
				left: 0;
			}
		}

		&.details {
			width: 55%;
			padding-left: @gutter / @em;

			.name {
				.faux-title();
				display: block;
			}

			.more {
				font-weight: bold;
			}
		}

		&.buy {
			width: 23%;

			.price {
				.faux-title();

				&.discount {color: @brand-orange;}

			}


			input {
				font-size: 14 / @em;
				padding: (9 / @em) (8 / @em) (7 / @em) (8 / @em);
				margin-bottom: 0;
				line-height: 1;
			}
		}

		.column-title .name {
			display: inline-block;
		}
	}
}
.normal-price {
	text-decoration: line-through;
	color: #999;
}
// @end Category page
// ----------------------------------------------------------------------------


// @group Product page
// ============================================================================
.shop.product {
	.clearfix;

	.product-info {
		.column(260);

		img {
			.centered;
			display: block;
		}

		.no-image {
			line-height: @line-height;
		}

		.row {
			border-top: 1px solid @border-color;

			&.border-bottom {
				border-bottom: 1px solid @border-color;
			}

			.cell {
				padding: (@baseline / @em) 0;
				display: inline-block;
				vertical-align: middle;
				width: 50%;

				&.prices {
					text-align: right;
				}

				input {
					margin-top: 0 !important;
					margin-bottom: 0 !important;
				}

				label {
					.faux-title(14);
					color: @text-color-dark;
					// font-size: 16 / @em;
				}
			}
		}

		.price {
			.faux-title();
		}
	}


	.not-orderable {
		display: block;
		padding: (20 / @em) 0;
		border-bottom: 1px solid #dadada;
		margin-bottom: 20 / @em;
	}

	.description {
		.column(370, right);

		p:last-child {
			a::after {
				content: " »";
			}
		}
	}
}
// @end Product page
// ----------------------------------------------------------------------------


// @group Basket
// ============================================================================

.basket {

	.confirm-title-row {
		border-bottom: 1px solid @brand-green;
		margin-bottom: 2em;

		h1.confirm-title {
			float: left;
			margin-top: .4em;
		}

		.upper-confirm-button {
			float: right;

			form {
				margin-bottom: 0;
			}

			input[type="submit"] {
				margin-top: 0;
			}
		}
	}

	h2.order-reference-number {
		color: @text-color;
		text-transform: none;
	}

	.payment-providers {
		form {
			margin:0;
			padding: 5px;
			float: left;
		}
		button {
			width: 120px;
			height: 100px;
			margin: 0;
			background: #fff;
			border: 1px solid #ccc;
			border-radius: 4px;
			&:hover, &:focus {
				background: darken(#fff, 10%);
			}
		}
		img {
			max-width: 100%;
			max-height: 100%;
		}
	}
}

.tax-report-table {
	th {
		font-size: 1em;
	}
}

.basket-table {
	.delete {
		.button-dark-grey();
	}

	.method-line {
		td {
			border: none;
			padding-top: @baseline / @em;
			padding-bottom: 0;
			font-family: @brand-font;
			font-weight: bold;
			font-size: 1em;

			.price {
				font-family: @brand-font !important;
				font-weight: bold;
			}
		}
	}

	th {
		font-size: 1em;
	}

	td {
		vertical-align: top;

		.product-name {
			.faux-title();
		}

		.price {
			.faux-title();
			color: @text-color;
			font-family: @brand-font;

			&.line-total {
				color: @brand-green;
				font-family: @brand-font;
				font-weight: bold;
			}
		}
	}

	td,
	th {
		&:first-child {
			padding-left: 0;
		}

		&:last-child {
			padding-right: 0;
		}
	}

	tbody {
		tr {
			&:last-child {
				td {
					border-color: @brand-green;
				}
			}
		}
	}


	.method-line + .method-line {
		td {
			padding-top: (@baseline / 2) / @em !important;
		}
	}

	tfoot {
		tr {

			&:first-child {
				td {
					padding-top: @baseline / @em;
				}
			}

			td {
				border-color: @brand-green;
				vertical-align: middle;

				input {
					margin: 0;
				}

				&.total,
				&.total-label {
					.faux-title();
				}

				small {
					font-weight: normal;
					font-size: 10 / @em;
					display: block;
				}
			}

		}
	}

}
// @end Basket
// ----------------------------------------------------------------------------


// @group Auth views
// ============================================================================
.auth {
	header {
		.banner {
			display: none;
		}
	}

	#sidebar {
		display: none;
	}

	#content {
		float: none;
		margin: 0 auto;
		width: 280 / @em;
	}
}
// @end Auth views
// ----------------------------------------------------------------------------
