@media
only screen and (max-width:420px),
only screen and (max-device-width:700px) {
// @group Imports
// ============================================================================
@import "alertify-mobile.less";
// @end Imports
// ----------------------------------------------------------------------------


// @group Mobile helpers and variables
// ============================================================================
@gutter: 10;

.mobile(@padding:@gutter) {
	.box-sizing(border-box);
	padding: 0 (@padding / @em);
	width: 100%;
	float: none;
}
// @end Mobile helpers and variables
// ----------------------------------------------------------------------------


// @group Faux tables
// ============================================================================
.row {
	.clearfix;
	border-top: 1px solid @border-color;

	.cell {
		.box-sizing(border-box);
		.pull;
		display: block;
		padding: ((@baseline / 2) / @em) 0;

		&.prices {
			text-align: center;

			.price {
				font-size: 22 / @em;
			}
		}
	}
}
// @end Faux tables
// ----------------------------------------------------------------------------


// @group Base
// ============================================================================
.mobile-only {
	display: block;
}

.desktop-only {
	display: none !important;
}

.header-wrap {
	margin: 0;
}

header {
	position: relative;
	margin: 0;
	padding: (@baseline / @em) 0;

	a.logo {
		width: 100%;

		.image {
			.box-sizing(border-box);
			width: 30%;

			img {
				width: 100%;
				height: auto;
			}
		}

		.name {
			width: 65%;
			font-size: 14 / @em;

			span {

			}
		}
	}
}

.banner-carousel {
	display: none;
}

img {
	max-width: 100%;
}

.container,
footer {
	.mobile();
}

#content,
#sidebar {
	.mobile(0);
}

#sidebar {
	.highlight {
		display: none;
	}
}

footer {
	section {
		width: 100%;
	}
	.column {
		width: 100% !important;
	}
}
// @end Base
// ----------------------------------------------------------------------------


// @group Basket
// ============================================================================
header {
	#basket {
		bottom: -30 / @em;
		height: 25 / @em;
		line-height: 25 / @em;
		margin: 0;
		max-width: 20%;
		padding-left: 40 / @em;
		position: absolute;
		right: ((0.5 * @baseline) / @em);

		.quiet,
		.column-2,
		.text {
			display: none;
		}

		.column-1 {
			border: none !important;
			padding: 0;

			.count {
				color: @text-color;
			}
		}
	}
}
// @end Basket
// ----------------------------------------------------------------------------


// @group Product search
// ============================================================================
#sidebar {
	.prescription-btn {
		width: auto;
		margin-bottom: 1em;
	}
	#product-search {
		width: 70%;
	}
}
// @end Product search
// ----------------------------------------------------------------------------


// @group Product and page menu
// ============================================================================
.menu-container {
	.leaf-node &,
	.product &,
	.basket &,
	.content-page &,
	.article-index &,
	.contact &,
	.feedback &,
	.shop.search & {
		display: none;
	}
}

.menu {
	.shop.index & {
		.level-0 {
			display: list-item;
		}
	}

	li {
		display: none;

		&.active,
		&.ancestor {
			display: list-item;

			> .children {
				display: list-item;

				li {
					display: list-item;
				}
			}
		}
	}
}

#page-menu, #product-menu {
	display: none;
}
.category {
	#product-menu {
		display: block;
	}
	.product-menu-header {
		a { color: @brand-green !important; }
	}
}
// @end Product menu
// ----------------------------------------------------------------------------


// @group Mobile menu (index)
// ============================================================================
.menu-container {
	margin-bottom: 2em !important;
	> ul:not(#product-menu) > li,
	.pages-container > ul > li {
		font-size: 18 / @em !important;
		font-weight: bold !important;
		margin: (5 / @em) 0 !important;
		a {
			line-height: 1em !important;
		}
		&.active, &.active a {
			background: none !important;
			color: @brand-green !important;
			text-decoration: none !important;
		}
		&.active a {
			padding: 0 !important;
			margin: 0 !important;
		}
		&.mobile-only-on-index {
			display: none;
		}
		body.index &.mobile-only-on-index {
			display: list-item;
		}
	}
	a { color: @text-color-light; }
	.active, .active>a {
		background: none !important;
		color: @brand-green !important;
		text-decoration: none !important;
	}
}

.mobile-menu-visible {

}
// @end Mobile menu (index)
// ----------------------------------------------------------------------------


// @group Breadcrumb
// ============================================================================
.breadcrumb {
	display: none;
}
// @end Breadcrumb: ;
// ----------------------------------------------------------------------------


// @group History navigation
// ============================================================================
.history-navigation {
	border-bottom: 1px solid @border-color;
	margin-top: (@baseline / 2) / @em;
	margin-bottom: @baseline / @em;
	.faux-title(14);

	.shop.index & {
		display: none;
	}

	a {
		display: block;
		padding: ((@baseline / 2) / @em) 0;
	}
}
// @end History navigation
// ----------------------------------------------------------------------------


// @group Index page
// ============================================================================
.shop.index {
	#page-menu {
		li {
			display: list-item;
		}
	}

	#content-contact {
		p:last-child {
			margin-bottom: @baseline / @em;
		}

		.description,
		.contact {
			.mobile(0);
		}
	}

	.showcase {
		> .row {
			border: 0;
		}

		&.right-column .product-wrap {
			flex: 0 0 50%;
			max-width: 50%;
		}

		&.center .product-wrap {
			flex: 0 0 50%;
			max-width: 50%;
		}
	}

	.articles {
		.mobile(0);
	}


	.description {
		border: none;
	}
}

// @end Index page
// ----------------------------------------------------------------------------


// @group Articles
// ============================================================================
.articles {
	article {
		img {
			margin: 0 10px 10px 0;
		}

		.article-content {
			width: 100%;
			float: none;
		}
	}
}
// @end Articles
// ----------------------------------------------------------------------------


// @group Category page
// ============================================================================
.shop.category,
.shop.search {
	&.leaf-node {
		.category-description {
			h1 {
				display: block;
			}
		}
	}

	.category-description {
		h1 {
			display: none;
		}
	}

	.product-row {
		padding: @baseline / @em 0;

		.image,
		.details,
		.buy {
			display: block;
		}

		.image {
			.pull;
			.box-sizing(border-box);
			width: 30%;
			padding-right: (2 * @gutter) / @em;

			.badge {
				width: auto;
				position: relative;
			}
		}

		.details {
			.pull;
			width: 70%;
			padding: 0;
			margin-bottom: @baseline / @em;
		}

		.buy {
			.box-sizing(border-box);
			.clearfix;
			width: 70%;
			margin: 0 0 0 30%;
			display: block;

			.prices {
				float: left;
				text-align: left;
			}

			form {
				float: right;

				input {
					float: none;
					margin: 0;
				}
			}
		}
	}
}
// @end Category page
// ----------------------------------------------------------------------------


// @group Product page
// ============================================================================
.shop.product {
	.product-info {
		width: 100%;

		.product-image {
			.pull;
			width: 45%;
			height: auto;
			margin: 0;
		}

		.row {
			border: none !important;
			.pull(right);
			width: 45%;

			.cell {
				float: none;
				width: 100%;
				text-align: center !important;
				padding: ((@baseline / 2) / @em) 0;

				&.prices {
					text-align: center;

					.price {
						font-size: 22 / @em;
					}
				}
			}
		}

		form {
			.pull(right);
			width: 50%;

			.row {
				float: none;
				width: auto;
			}

			label {
				display: block;
			}

			input[type=submit] {
				font-size: 18 / @em;
			}
		}
	}

	.description {
		width: 100%;
	}
}
// @end Product page
// ----------------------------------------------------------------------------


// @group Basket page
// ============================================================================
.shop.basket {
	.row-1 {
		.cell {
			&.column-1 {
				width: 15%;
			}

			&.column-2 {
				width: 60%;
				padding-left: (2 * @gutter) / @em;
			}

			&.column-3 {
				width: 25%;
			}

			.product-name {
				.faux-title();
			}
		}
	}

	.row-2 {
		border-top: none;

		.cell {
			width: 100/3%;
			text-align: center;

			.header {
				.faux-title(14);
				color: @text-color;
				display: block;
				margin-bottom: (@baseline / 3) / @em;
			}

			.price {
				font-family: @brand-font;
			}

			select {
				width: auto;
			}

			&.column-3 {
				.price {
					font-family: @brand-font;
					font-weight: bold;
					color: @brand-green;
				}
			}
		}
	}

	.method-line {
		.cell {
			.faux-title(14);
			text-align: right;
			text-transform: none;
			color: @text-color;

			&.column-1 {
				width: 85%;
			}

			&.column-2 {
				width: 15%;
			}
		}
	}

	.tfoot {
		.row {
			border: 1px solid @brand-green;
			border-width: 1px 0;

			.cell {
				&.column-1 {
					width: 30%;

					button {
						max-width: 100%;
						margin: 0;
					}
				}

				&.column-2 {
					.pull(right);
					.faux-title(16);
					text-align: right;
					text-transform: none;
					width: 70%;
					line-height: (2 * @baseline) / @em;
				}
			}
		}
	}

	form {
		label {
			display: block;
			width: auto;
		}

		input[type=text],
		select,
		textarea {
			width: 100% !important;
		}

		.checkbox-input {
			label {
				display: inline;
				width: auto;
			}
		}

		.field {
			&.radio-select {
				ul {
					margin-left: 0 !important;
					width: 100% !important;
				}
			}
		}
	}
}
// @end Basket page
// ----------------------------------------------------------------------------


// @group Basket page
// ============================================================================
.shop.page {
	.menu-container {
		h2 {
			display: none;
		}
	}
}
// @end Content page
// ----------------------------------------------------------------------------
}
