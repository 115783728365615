// @group Transitions
// ============================================================================
.alertify-show,
.alertify-log {
	-webkit-transition: all 500ms cubic-bezier(0.175, 0.885, 0.320, 1); /* older webkit */
	-webkit-transition: all 500ms cubic-bezier(0.175, 0.885, 0.320, 1.275); 
	   -moz-transition: all 500ms cubic-bezier(0.175, 0.885, 0.320, 1.275); 
	    -ms-transition: all 500ms cubic-bezier(0.175, 0.885, 0.320, 1.275); 
	     -o-transition: all 500ms cubic-bezier(0.175, 0.885, 0.320, 1.275); 
	        transition: all 500ms cubic-bezier(0.175, 0.885, 0.320, 1.275); /* easeOutBack */
}
.alertify-hide {
	-webkit-transition: all 250ms cubic-bezier(0.600, 0, 0.735, 0.045); /* older webkit */
	-webkit-transition: all 250ms cubic-bezier(0.600, -0.280, 0.735, 0.045); 
	   -moz-transition: all 250ms cubic-bezier(0.600, -0.280, 0.735, 0.045); 
	    -ms-transition: all 250ms cubic-bezier(0.600, -0.280, 0.735, 0.045); 
	     -o-transition: all 250ms cubic-bezier(0.600, -0.280, 0.735, 0.045); 
	        transition: all 250ms cubic-bezier(0.600, -0.280, 0.735, 0.045); /* easeInBack */
}
// @end Transitions
// ----------------------------------------------------------------------------


// @group Cover
// ============================================================================
.alertify-cover {
	.box-shadow(0 0 500px 0 rgba(0, 0, 0, .8) inset);
	background: rgba(0, 0, 0, .2);
	position: fixed; 
	bottom: 0; 
	left: 0;
	right: 0; 
	top: 0; 
	z-index: 99999;
}

.alertify-hidden { 
	top: -50px;
	visibility: hidden;
}
// @end Cover
// ----------------------------------------------------------------------------


// @group Dialog
// ============================================================================
.alertify {
	position: fixed; z-index: 99999;
	top: 50px; left: 50%;
	width: 550px;
	margin-left: -275px;
	background-color: white;
	text-align: center;
	// padding: (@gutter / @em) 0;

	.alertify-message {
		.faux-title();
		padding-top: @baseline / @em;
	}

	.alertify-buttons {
		.alertify-button {
			.button();
			margin: 0 (@gutter / 2) / @em;
		}

		.alertify-button-ok {
			.button-green();
		}
	}
}
// @end Dialog
// ----------------------------------------------------------------------------