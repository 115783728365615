// @group Flow
// ============================================================================
.pull(@direction:left) {
	float: @direction;
}

.right {
	.pull(right);

	&.no-mobile-float {
		@media only screen and (max-width:420px), only screen and (max-device-width:700px) {
			float: none;
		}
	}
}

.left {
	.pull(e("left !important"));

	&.no-mobile-float {
		@media only screen and (max-width:420px), only screen and (max-device-width:700px) {
			float: none;
		}
	}
}

.clear {
	clear: both;
}

.clearfix {
	zoom:1;

	&:before,
	&:after {
		content:"";
		display:table;
	}

	&:after {
		clear:both;
	}
}

.centered {
	margin: 0 auto;
}

.block {
	display: block;
}
// @end Flow
// ----------------------------------------------------------------------------


// @group Typographic helpers
// ============================================================================
.uppercase {
	text-transform: uppercase;
}

.lowercase {
	text-transform: lowercase;
}

.text-right {
	text-align: right !important;
}

.text-center {
	text-align: center !important;
}

.text-left {
	text-align: left !important;
}

.text-error {
	color: @text-color-error;
}
// @end Typographic helpers
// ----------------------------------------------------------------------------
